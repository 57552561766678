import { queryOptions } from '@tanstack/react-query';

import { getActiveAnnouncementsForUser, getAllAnnouncementsForUser } from '../api/announcement';

export const announcementQueryKeys = {
	all: () => ['announcement'],
	activeAnnouncement: () => ['announcement', 'active'],
	userAnnouncements: () => ['announcement', 'user'],
};

export const getActiveAnnouncementsQueryOptions = (enabled: boolean = true) =>
	queryOptions({
		queryKey: announcementQueryKeys.activeAnnouncement(),
		queryFn: ({ signal }) => getActiveAnnouncementsForUser(signal),
		enabled,
	});

export const getUserAnnouncementsQueryOptions = queryOptions({
	queryKey: announcementQueryKeys.userAnnouncements(),
	queryFn: ({ signal }) => getAllAnnouncementsForUser(signal),
});
