/** Service wrapper for
 * - AppleBrandedPrograms.Web.Features.CustomerManagementController */

import axios from 'axios';

import { customerSchema } from './management.models';
import type { Customer } from './management.models';

export async function getSingleCustomer(customerCode: string): Promise<Customer> {
	const response = await axios.get<Customer>(`/api/management/customers/${customerCode}`);

	return customerSchema.parse(response.data);
}

export async function addCustomer(customerModel: Customer): Promise<void> {
	await axios.post('/api/management/customers/add', customerModel);
}

export async function saveCustomer(customerModel: Customer): Promise<void> {
	await axios.put('/api/management/customers/save', customerModel);
}

export async function deleteCustomer(customerCode: string): Promise<void> {
	await axios.delete(`/api/management/customers/${customerCode}`);
}
