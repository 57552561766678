import { rem } from '@mantine/core';

const fontFamilies = [
	'SF Pro Text',
	'SF Pro Icons',
	'-apple-system',
	'BlinkMacSystemFont',
	'Helvetica',
	'Arial',
	'sans-serif',
	'Apple Color Emoji',
	'Segoe UI Emoji',
];
export const titleFontFamlies = ['SF Pro Display', ...fontFamilies].join(', ');
export const bodyFontFamlies = fontFamilies.join(', ');

export const fonts = {
	largeTitle: {
		fontFamily: titleFontFamlies,
		fontSize: rem(34),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '700',
		lineHeight: rem(41),
		letterSpacing: rem(0.374),
	},
	title1: {
		fontFamily: titleFontFamlies,
		fontSize: rem(28),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '700',
		lineHeight: rem(34),
		letterSpacing: rem(0.364),
	},
	title2: {
		fontFamily: titleFontFamlies,
		fontSize: rem(22),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '700',
		lineHeight: rem(28),
		letterSpacing: rem(0.35),
	},
	title3: {
		fontFamily: titleFontFamlies,
		fontSize: rem(20),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '600',
		lineHeight: rem(25),
		letterSpacing: rem(0.38),
	},
	headline: {
		fontFamily: bodyFontFamlies,
		fontSize: rem(17),
		fontStyle: 'normal',
		fontWeightRegular: '600',
		fontWeightBold: '600',
		lineHeight: rem(22),
		letterSpacing: rem(-0.408),
	},
	body: {
		fontFamily: bodyFontFamlies,
		fontSize: rem(17),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '600',
		lineHeight: rem(22),
		letterSpacing: rem(-0.408),
	},
	callout: {
		fontFamily: bodyFontFamlies,
		fontSize: rem(16),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '600',
		lineHeight: rem(21),
		letterSpacing: rem(-0.32),
	},
	subheadline: {
		fontFamily: bodyFontFamlies,
		fontSize: rem(15),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '600',
		lineHeight: rem(20),
		letterSpacing: rem(-0.24),
	},
	footnote: {
		fontFamily: bodyFontFamlies,
		fontSize: rem(13),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '600',
		lineHeight: rem(18),
		letterSpacing: rem(-0.078),
	},
	caption1: {
		fontFamily: titleFontFamlies,
		fontSize: rem(12),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '500',
		lineHeight: rem(16),
		letterSpacing: '0',
	},
	caption2: {
		fontFamily: titleFontFamlies,
		fontSize: rem(11),
		fontStyle: 'normal',
		fontWeightRegular: '400',
		fontWeightBold: '600',
		lineHeight: rem(13),
		letterSpacing: rem(0.066),
	},
} as const;
