import axios from 'axios';
import { getLogger } from 'loglevel';

import type { CountryCode } from '@apple/utils/globalization';

import { characterWhitelistResponseSchema, validationFieldRuleResponseSchema } from './models';
import type { CharacterWhitelist, ValidationRule, ValidationType } from './models';

const log = getLogger('validation');

export type ValidationFormFieldOptions = {
	validationType: ValidationType;
	countryCode?: CountryCode;
};

export async function getValidationFormRules(
	{ validationType, countryCode }: ValidationFormFieldOptions,
	signal?: AbortSignal,
): Promise<ValidationRule[]> {
	const path = countryCode
		? `/api/validation/form-rules/${validationType}/${countryCode}`
		: `/api/validation/form-rules/${validationType}`;
	const response = await axios.get(path, { signal });
	try {
		return validationFieldRuleResponseSchema.parse(response.data);
	} catch (ex) {
		log.error('Error getting form validation rules:', ex);
		return validationFieldRuleResponseSchema.parse(response.data);
	}
}

export async function getAddressRules(
	countryCode: string,
	signal?: AbortSignal,
): Promise<ValidationRule[]> {
	try {
		const path = `/api/validation/address-form-rules/${countryCode}`;
		const response = await axios.get(path, { signal });
		return validationFieldRuleResponseSchema.parse(response.data);
	} catch (ex) {
		log.error('Error getting address validation rules:', ex);
		throw ex;
	}
}

export async function getValidationCharacterWhitelist(
	signal?: AbortSignal,
): Promise<CharacterWhitelist> {
	const response = await axios.get('/api/validation/whitelist', { signal });
	return characterWhitelistResponseSchema.parse(response.data);
}

export async function getAddressCharacterWhitelist(countryCode: string, signal?: AbortSignal) {
	const response = await axios.get(`/api/validation/address-whitelist/${countryCode}`, {
		signal,
	});
	return characterWhitelistResponseSchema.parse(response.data);
}
