import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getPlants } from './api';

export const plantsQueryOptions = queryOptions({
	queryKey: ['plants'],
	queryFn: () => getPlants(),
	placeholderData: keepPreviousData,
	staleTime: Infinity,
});
