import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getApprovalDetail, getApprovalOrders } from '../api/approval';

export const getApprovalOrdersOptions = queryOptions({
	queryKey: ['approval-orders'],
	queryFn: ({ signal }) => getApprovalOrders(signal),
	placeholderData: keepPreviousData,
	throwOnError: true,
	retry: false,
	initialData: [],
});

export const getApprovalDetailOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['approval-details', poNumber],
		queryFn: ({ signal }) => getApprovalDetail(poNumber, signal),
	});
