import { useMemo } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Navigate, Outlet } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import type { PropsWithChildren } from 'react';

import { useAuthContext } from '../hooks/useAuthContext';
import type { RoleName } from '../models/roles';
import type { Permission } from '../models/user';

const log = getLogger('auth:require-auth');

export type RequireAuthProps = PropsWithChildren<{
	authenticated?: boolean;
	role?: RoleName;
	roles?: RoleName[];
	permission?: Permission;
	permissions?: Permission[];
}>;

/**
 * Redirect's the user to the LoginPage if not authenticated.
 * Redirect's the user to the NotFoundPage if not authorized.
 **/
export function RequireAuth({ role, roles, permission, permissions, children }: RequireAuthProps) {
	const user = useAuthContext();
	const appInsights = useAppInsightsContext();
	const hasRequired = useMemo(() => {
		return {
			role: role && !user.hasRoles([role]),
			roles: roles && !user.hasRoles(roles),
			permission: permission && !user.hasPermissions([permission]),
			permissions: permissions && !user.hasPermissions(permissions),
		};
	}, [role, roles, permission, permissions, user]);

	if (!user.authenticated) {
		appInsights.trackEvent({
			name: 'not-authenticated',
			properties: {
				path: location.pathname,
				requested: { role, roles, permission, permissions },
			},
		});

		// Redirect to the login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		log.info('User is not authenticated, redirecting to login');
		return (
			<Navigate
				to='/login'
				from={location.href}
				search={{
					returnUrl: location.pathname + location.search,
				}}
				replace
			/>
		);
	}

	// TODO: Should these be OR's or AND's?
	if (
		hasRequired.role ||
		hasRequired.roles ||
		hasRequired.permission ||
		hasRequired.permissions
	) {
		appInsights.trackEvent({
			name: 'not-authorized',
			properties: {
				path: location.pathname,
				requested: { role, roles, permission, permissions },
				user: {
					roles: user.profile?.roles,
					permissions: user.profile?.permissions,
					hasRequired,
				},
			},
		});

		log.info('User does not have required permissions, redirecting to not found');
		return <Navigate to='/not-found' from={location.href} replace />;
	}

	return children ? children : <Outlet />;
}
