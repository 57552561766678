import { SidebarToggleButton } from '@apple/ui/layouts/components/sidebar-toggle-button';
import { useLeftSidebarCollapsed } from '@apple/ui/shell/contexts/left-sidebar';
import type { SidebarToggleButtonProps } from '@apple/ui/layouts/components/sidebar-toggle-button';

type Props = Omit<SidebarToggleButtonProps, 'collapsed' | 'onClick'> & {
	hideWhen?: 'collapsed' | 'expanded';
};

export function ToggleLeftSidebarButton({ hideWhen, ...props }: Props) {
	const { isCollapsed, toggle } = useLeftSidebarCollapsed();
	const shouldHide =
		hideWhen !== undefined && (hideWhen === 'collapsed' ? isCollapsed : !isCollapsed);
	return shouldHide ? null : (
		<SidebarToggleButton {...props} collapsed={isCollapsed} onClick={toggle} />
	);
}
