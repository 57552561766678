import axios from 'axios';

import { customerIdSchema } from '@apple/features/customer/management.models';
import { countryCodeSchema } from '@apple/utils/globalization';

import { programListingSchema } from './management.models';
import { programSchema } from './models';
import type { Program, ProgramFilters, ProgramId } from './models';

export async function getPrograms(
	params?: ProgramFilters,
	signal?: AbortSignal,
): Promise<Program[]> {
	const data = (await axios.get<unknown>('/api/customer-groups', { params: params, signal }))
		.data;
	return programSchema.array().parse(data);
}

export async function getAllPrograms(signal?: AbortSignal) {
	const data = (await axios.get<unknown>('/api/programs/all', { signal })).data;
	return programListingSchema.array().parse(data);
}

export async function getProgramCountries(programId: ProgramId, signal?: AbortSignal) {
	const data = (await axios.get<unknown>(`/api/programs/countries/${programId}`, { signal }))
		.data;
	return countryCodeSchema.array().parse(data);
}

export async function getProgramCustomers(programId: ProgramId, signal?: AbortSignal) {
	const data = (await axios.get<unknown>(`/api/programs/customers/${programId}`, { signal }))
		.data;
	return customerIdSchema.array().parse(data);
}
