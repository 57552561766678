import { Box, Grid, Stack } from '@mantine/core';
import type { CellContext } from '@tanstack/table-core';

import { UserAnnouncements } from '@apple/features/announcements/components/UserAnnouncements';
import { useUserAnnouncements } from '@apple/features/announcements/hooks/useUserAnnouncements';
import { OrdersPendingApprovalTable } from '@apple/features/order/components/OrdersPendingApprovalTable';
import type { OrderApprovalListing } from '@apple/features/order/models/approval';

import { Dashboard } from './Dashboard';
import type { DashboardCard } from './Dashboard';

type Props = {
	cards: DashboardCard[];
	renderOrderLink: (ctx: CellContext<OrderApprovalListing, unknown>) => React.ReactNode;
};

export function AdminDashboard({ cards, renderOrderLink }: Props) {
	const userAnnouncements = useUserAnnouncements();

	return (
		<Grid gutter={{ base: 5, xs: 'md', md: 'xl', xl: 10 }}>
			<Grid.Col span={{ base: 12, lg: 6 }}>
				<Stack>
					<Dashboard cards={cards} />
					{!userAnnouncements.isLoading &&
						!userAnnouncements.error &&
						userAnnouncements.data && (
							<Box py='sm' px='md' mx='xl'>
								<UserAnnouncements announcementList={userAnnouncements.data} />
							</Box>
						)}
				</Stack>
			</Grid.Col>
			<Grid.Col span={{ base: 12, lg: 6 }}>
				<OrdersPendingApprovalTable renderOrderLink={renderOrderLink} />
			</Grid.Col>
		</Grid>
	);
}
