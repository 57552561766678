import { z } from 'zod';

export const EXCEL_MAX_FILE_SIZE = 5 * 1024 ** 2;
export const EXCEL_ALLOWED_FILE_TYPES = ['.xlsx'];

interface FieldError {
	[field: string]: string[];
}

export type ExcelError<TItem extends Record<string, unknown>, TKey extends keyof TItem> = {
	[key in TKey]: TItem[TKey];
} & {
	errors: FieldError;
};

export interface ExcelResult<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
> {
	readonly inserts: TItem[];
	readonly updates: TItem[];
	readonly deletes: TItem[TKey][];
	readonly errors: TError[];
}

// Creates a schema for the response for some bulk management apis
export function createExcelResultSchema<
	TItemSchema extends z.ZodTypeAny,
	TItemErrorSchema extends z.ZodTypeAny,
	TKeyValueSchema extends z.ZodType<string | number>,
>(itemSchema: TItemSchema, errorSchema: TItemErrorSchema, keyValueSchema: TKeyValueSchema) {
	return z
		.object({
			inserts: z.array(itemSchema),
			updates: z.array(itemSchema),
			deletes: z.array(keyValueSchema),
			errors: z.array(errorSchema),
		})
		.readonly();
}

export function createExcelErrorSchema<TItemKeySchema extends z.ZodTypeAny>(
	keySchema: TItemKeySchema,
) {
	return z.intersection(
		keySchema,
		z.object({ errors: z.record(z.string(), z.array(z.string())) }),
	);
}
