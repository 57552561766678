import { memo } from 'react';
import { Paper, Table } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import classes from './list.module.css';

export const List = memo(_List);
List.displayName = 'List';

function _List({ title, children }: PropsWithChildren<{ title: string }>) {
	return (
		<Paper styles={{ root: { overflow: 'hidden' } }} shadow='md'>
			<Table withRowBorders>
				{title && title !== '' && (
					<Table.Thead>
						<Table.Tr className={classes.header}>
							<Table.Th>{title}</Table.Th>
						</Table.Tr>
					</Table.Thead>
				)}

				<Table.Tbody>{children}</Table.Tbody>
			</Table>
		</Paper>
	);
}
