import { z } from 'zod';

export interface Merchandiser {
	appleId: string;
	countryCode: string;
	id: string | null;
	merchandiserName: string;
	programIds: number[];
	hasUsersWithAccess: boolean;
}

export const merchandiserSchema = z.object({
	appleId: z.string(),
	countryCode: z.string(),
	id: z.string().nullable(),
	merchandiserName: z.string(),
	programIds: z.array(z.number()),
	hasUsersWithAccess: z.boolean(),
}) satisfies z.ZodType<Merchandiser>;
