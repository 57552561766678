import { queryOptions } from '@tanstack/react-query';

import { getLocations, getLocationsByIds } from '../api/location';
import type { LocationId, LocationListingRequest } from '../models/location';

export const locationQueries = {
	getLocations: (request: LocationListingRequest) =>
		queryOptions({
			queryKey: ['locations', request],
			queryFn: ({ signal }) => getLocations(request, signal),
		}),

	getLocationsByIds: (locationIds: LocationId[]) =>
		queryOptions({
			queryKey: ['locations', locationIds],
			queryFn: ({ signal }) => getLocationsByIds(locationIds, signal),
		}),
};
