import { createElement } from 'react';
import { notifications } from '@mantine/notifications';
import axios, { CanceledError } from 'axios';
import log from 'loglevel';

import { Error } from '@apple/assets/icons';
import { ServerError } from '@apple/utils/api/errors';

import { approveOrdersResponseSchema } from '../models/approval';
import type {
	ApprovalDetailResponse,
	ApproveOrdersRequest,
	ApproveOrdersResponse,
	ApproveQuantityUpdateResponse,
	OrderApprovalListing,
	PurchaseOrderUpdate,
	RejectOrdersRequest,
	ReviewOrdersRequest,
} from '../models/approval';

const unexpectedSearchErrorMessage =
	"We're sorry - an error occurred while fetching your orders. Please try again in a few minutes. Contact support if the problem persists.";

export async function getApprovalOrders(signal?: AbortSignal): Promise<OrderApprovalListing[]> {
	try {
		const response = await axios.get<OrderApprovalListing[]>('/api/approval-queue', { signal });

		return response.data;
	} catch (err) {
		if (err instanceof ServerError && !(err.innerError instanceof CanceledError)) {
			log.error('Error searching approval orders:', err);

			notifications.show({
				color: 'red',
				message: unexpectedSearchErrorMessage,
				icon: createElement(Error),
			});
			return [];
		}

		throw err;
	}
}

export async function approveOrders(request: ApproveOrdersRequest): Promise<ApproveOrdersResponse> {
	const response = await axios.post<ApproveOrdersResponse>(
		'/api/approval-queue/approve-orders',
		request,
	);

	return response.data;
}

export async function rejectOrders(request: RejectOrdersRequest): Promise<ApproveOrdersResponse> {
	const response = await axios.post<ApproveOrdersResponse>(
		'/api/approval-queue/reject-orders',
		request,
	);

	return response.data;
}

export async function markForReviewOrders(
	request: ReviewOrdersRequest,
): Promise<ApproveOrdersResponse> {
	const response = await axios.post<ApproveOrdersResponse>(
		'/api/approval-queue/review-orders',
		request,
	);

	return response.data;
}

export async function getApprovalDetail(
	poNumber: string,
	signal?: AbortSignal,
): Promise<ApprovalDetailResponse> {
	const response = await axios.get<ApprovalDetailResponse>(
		`/api/approval-queue/${poNumber}/approval-details`,
		{ signal },
	);

	return response.data;
}

export async function evaluateRules(
	request: PurchaseOrderUpdate,
): Promise<ApproveQuantityUpdateResponse> {
	const response = await axios.post<ApproveQuantityUpdateResponse>(
		'/api/approval-queue/orders/evaluate-rules',
		request,
	);

	return response.data;
}

export async function saveOrder(request: PurchaseOrderUpdate): Promise<ApproveOrdersResponse> {
	const data = (await axios.post<unknown>('/api/approval-queue/orders', request)).data;
	return approveOrdersResponseSchema.parse(data);
}
