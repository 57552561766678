import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Anchor, Stack } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import type { DropzoneProps, FileWithPath } from '@mantine/dropzone';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

export type AddImageProps = {
	onFilesSelected: (files: FileWithPath[]) => Promise<void>;
	maxSize?: number;
} & Partial<DropzoneProps>;

export const AddImage = forwardRef(
	({ onFilesSelected, maxSize = 5 * 1024 ** 2, ...others }: AddImageProps, ref) => {
		const { t } = useTranslation('product');
		const openRef = useRef<() => void>(null);

		useImperativeHandle(ref, () => ({
			open: () => {
				if (openRef.current) {
					openRef.current();
				}
			},
		}));

		return (
			<Dropzone
				onDrop={files => void onFilesSelected(files)}
				maxSize={maxSize}
				accept={IMAGE_MIME_TYPE}
				openRef={openRef}
				{...others}
			>
				<Stack align='center' style={{ pointerEvents: 'none' }}>
					<Dropzone.Accept>
						<icons.AddIcon size={80} color='var(--mantine-color-blue-6)' />
					</Dropzone.Accept>
					<Dropzone.Reject>
						<icons.Close size={80} color='var(--mantine-color-red-6)' />
					</Dropzone.Reject>
					<Dropzone.Idle>
						<icons.Image size={80} color='var(--mantine-color-dimmed)' />
					</Dropzone.Idle>
					<Anchor>{t('details.images.actions.add')}</Anchor>
				</Stack>
			</Dropzone>
		);
	},
);
