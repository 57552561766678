import axios from 'axios';

import { merchandiserSchema } from '../models/management';
import type { Merchandiser } from '../models/management';

export async function getMerchandiser(locationId: string): Promise<Merchandiser> {
	const data = (await axios.get<unknown>(`/api/management/merchandisers/${locationId}`)).data;
	return merchandiserSchema.parse(data);
}

export async function addMerchandiser(request: Merchandiser): Promise<void> {
	await axios.post('/api/management/merchandisers', request);
}

export async function updateMerchandiser(request: Merchandiser): Promise<void> {
	await axios.put('/api/management/merchandisers', request);
}
export async function deleteMerchandiser(id: string): Promise<void> {
	await axios.delete(`/api/management/merchandisers/${id}`);
}
