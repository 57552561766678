import { rem } from '@mantine/core';
import type { ActionIconProps } from '@mantine/core';

import classes from './toolbar-button.module.css';

export const TOOLBAR_HEIGHT = rem(78);

export const toolbarCommonProps: Partial<ActionIconProps> = {
	className: classes.button,
	variant: 'subtle',
};
