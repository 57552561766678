import type { SerializableRecord } from './primitive';

export type UrlPath = `/${string}`;
export type Uri = string;

/**
 * Properly encodes a string to be used with the :decodeslash route parameter constraint on the server.
 * This is necessary when passing a value with a forward slash '/' as an ID to a restful endpoint.
 * Unfortunately using a well known mechanism like UrlEncode stopped functioning server side due to hosting dependency changes.
 * HACK: This is not the proper way to handle this. Remove when item ID has been cleaned of the forward slash '/' character.
 * HACK: https://arvato-ecommerce.visualstudio.com/Apple/_workitems/edit/25969
 */
export function encodeSlashes(value: string): string {
	while (value.indexOf('/') !== -1) {
		value = value.replace('/', '___');
	}

	return value;
}

export function decodeSlashes(value: string): string {
	while (value.indexOf('___') !== -1) {
		value = value.replace('___', '/');
	}

	return value;
}

export function toQueryString<T>(obj: SerializableRecord<T>): string {
	return Object.entries(obj)
		.map(([key, value]) => {
			const encodedKey = encodeURIComponent(key);
			const encodedValue = encodeURIComponent(value?.toString() ?? '');
			return `${encodedKey}=${encodedValue}`;
		})
		.join('&');
}
