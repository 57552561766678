import { z } from 'zod';

import { countryCodeSchema, dateTimeSchema } from '@apple/utils/globalization';
import type { LocationProgramListing, ProgramId } from '@apple/features/program/models';
import type { Country, CountryCode } from '@apple/utils/globalization';
import type { PaginatedListDto } from '@apple/utils/pagination';
import type { Guid } from '@apple/utils/primitive';

import { locationUserSchema } from './location-approval';
import type { LocationUser } from './location-approval';

export type LocationId = Guid;

export interface Location {
	id: LocationId;
	appleId: string;
	storeName: string;
	retailerName: string;
	storeAddressLine1: string;
	storeAddressLine2: string;
	storeAddressLine3: string;
	storeCity: string;
	storeStateOrProvince: string;
	storePostalCode: string;
	storeCountryCode: CountryCode;
	customerGroupIds: ProgramId[];
	isDeleted: boolean;
}

export interface LocationListingRequest {
	countryCode?: CountryCode;
	customerGroupId?: ProgramId;
	currentPage?: number;
	searchText?: string;
	pageSize?: number;
}

export interface LocationListing {
	id: LocationId;
	locationName: string;
	appleId: string;
	resellerName: string;
	userId: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	city: string;
	stateOrProvince: string;
	countryCode: CountryCode;
	countryName: string;
	postalCode: string;
	programs: LocationProgram[];
	locationType: string;
}

export interface LocationProgram {
	readonly customerGroupId: ProgramId;
	readonly customerGroupName: string;
}

export interface LocationListingResponse {
	locations: PaginatedListDto<LocationListing>;
	availableCountries: Country[];
	availablePrograms: LocationProgramListing[];
}

export enum LocationType {
	appleFeed = 'AppleFeed',
	merchandiser = 'Merchandiser',
}

export interface LocationAutocompleteRequest {
	searchText: string;
	locationIds: LocationId[];
}

/**
 * @see AppleBrandedPrograms.Data.AppleBranded.Location
 */
export interface LocationDataModel {
	locationId: LocationId;
	appleId: string;
	displayName: string;
	storeAddressLine1: string | null;
	storeAddressLine2: string | null;
	storeAddressLine3: string | null;
	storeCity: string | null;
	storeStateOrProvince: string | null;
	storePostalCode: string | null;
	resellerName: string;
	storeCountryCode: string;
	phoneNumber: string | null;
	updatedDate: string | Date | null;
	headquartersId: string | null;
	countryTaxId: string | null;
	shipToAddressId: string | null;
	internationalShipToAddressId: string | null;
}

export interface LocationRequestResponse {
	user: LocationUser;
	roles: string[];
	requestedLocations: LocationDataModel[];
	existingLocations: LocationDataModel[];
}

export const locationDataModelSchema = z.object({
	locationId: z.string().uuid(),
	displayName: z.string(),
	resellerName: z.string(),
	appleId: z.string(),
	storeAddressLine1: z.string().nullable(),
	storeAddressLine2: z.string().nullable(),
	storeAddressLine3: z.string().nullable(),
	storeCity: z.string().nullable(),
	storeStateOrProvince: z.string().nullable(),
	storePostalCode: z.string().nullable(),
	storeCountryCode: countryCodeSchema,
	isDeleted: z.boolean(),
	phoneNumber: z.string().nullable(),
	updatedDate: dateTimeSchema.nullable(),
	headquartersId: z.string().nullable(),
	countryTaxId: z.string().nullable(),
	shipToAddressId: z.string().nullable(),
	internationalShipToAddressId: z.string().nullable(),
}) satisfies z.ZodType<LocationDataModel>;

export const locationRequestResponseSchema = z.object({
	user: locationUserSchema,
	roles: z.array(z.string()),
	requestedLocations: z.array(locationDataModelSchema),
	existingLocations: z.array(locationDataModelSchema),
}) satisfies z.ZodType<LocationRequestResponse>;
