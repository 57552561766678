import { Button, Center, Stack, Text, Title } from '@mantine/core';

import { useTranslation } from '@apple/lib/i18next';
import { Link } from '@apple/ui/link';

export function NotFoundPage() {
	const { t } = useTranslation('common');

	return (
		<Center pt={28}>
			<Stack align='center'>
				<Title>{t('error.notFound.title')}</Title>
				<Text>{t('error.notFound.message')}</Text>
				{/* FIXME: This button is redirecting to home but says back */}
				<Button h='xl' variant='outline' component={Link} to='/'>
					{t('buttons.back')}
				</Button>
			</Stack>
		</Center>
	);
}
