import axios from 'axios';
import { default as moment } from 'moment';

import { CartErrorType } from '@apple/features/cart/models/submission';
import { ServerError } from '@apple/utils/api';
import { encodeSlashes } from '@apple/utils/url';
import type { OrderShipToAddress } from '@apple/features/address/models';
import type {
	ShippingOption,
	UpdateLocationShippingMethodRequest,
} from '@apple/features/cart/models/shipping';
import type {
	CompleteBulkOrderResponse,
	CompleteRequestOptions,
	CompleteResponse,
	OrderReason,
} from '@apple/features/cart/models/submission';
import type { LocationId } from '@apple/features/location';
import type {
	OrderItemFilter,
	OrderItemsResponse,
	UpdateLineItemQuantityRequest,
} from '@apple/features/order/models/item';
import type {
	LocationSelection,
	UpdateLocationSelectionRequest,
} from '@apple/features/order/models/location';
import type { ProductKey } from '@apple/features/product';
import type { ProductFavoriteRequest } from '@apple/features/product/models/product.models';

import type { BulkOrder, BulkOrderInfo, CartId, SaveBulkOrderRequest } from '../models/models';
import type { GetProgramsSelectionRequest, ProgramSelection } from '../models/program';

// import { StepToComplete } from '../types/orderServiceTypes';

/** Service wrapper for AppleBrandedPrograms.Web.Features.Shop.ShopLocationSelectionController */
/** Service wrapper for AppleBrandedPrograms.Web.Features.Shop.ShopProductSelectionController */
/** Service wrapper for AppleBrandedPrograms.Web.Features.Shop.ShopOrderItemsQuantityController */
/** Service wrapper for AppleBrandedPrograms.Web.Features.Shop.ShopOrderItemsController */

/**
 * Get all selected location for the current order.
 * @returns {LocationId[]} A collection of all selected location Id's.
 */
export async function getLocationSelections(bulkOrderId: CartId, signal?: AbortSignal) {
	return (
		await axios.get<LocationId[]>(`/api/shop/location-selection/${bulkOrderId}/all`, {
			signal,
		})
	).data;
}

export async function setLocationSelections(
	request: UpdateLocationSelectionRequest,
	signal?: AbortSignal,
) {
	return (
		await axios.post<LocationSelection[]>('/api/shop/location-selection/location', request, {
			signal,
		})
	).data;
}

export async function getSelectedPrograms(bulkOrderId: CartId, signal?: AbortSignal) {
	return (
		await axios.post<ProgramSelection[]>(
			'/api/shop/location-selection/selected-programs',
			{
				bulkOrderId,
			} as GetProgramsSelectionRequest,
			{
				signal,
			},
		)
	).data;
}

export async function getSelectedLocationsPlantCount(bulkOrderId: CartId, signal?: AbortSignal) {
	return (
		await axios.post<number>(
			'/api/shop/location-selection/selected-plants',
			{
				bulkOrderId,
			} satisfies GetProgramsSelectionRequest,
			{
				signal,
			},
		)
	).data;
}

export async function clearAllLocationSelections(bulkOrderId: CartId, signal?: AbortSignal) {
	await axios.delete<void>(`/api/shop/location-selection/${bulkOrderId}/all`, {
		signal,
	});
}

/**
 * Get all selected products for the current order.
 * @returns {ProductKey[]} A collection of all selected item IDs.
 */
export async function getProductSelections(bulkOrderId: CartId, signal?: AbortSignal) {
	return (
		await axios.get<ProductKey[]>(`/api/shop/product-selection/${bulkOrderId}`, {
			signal,
		})
	).data;
}

/**
 * Marks a product as selected for the current order.
 * @returns {ProductKey[]} A collection of all selected item IDs.
 */
export async function addProductToSelection(
	bulkOrderId: CartId,
	itemId: ProductKey,
	signal?: AbortSignal,
) {
	return (
		await axios.post<ProductKey[]>(
			`/api/shop/product-selection/${bulkOrderId}/${encodeSlashes(itemId)}`,
			{ signal },
		)
	).data;
}

/**
 * Removes a product as selected for the current order.
 * @returns {ProductKey[]} A collection of all selected item IDs.
 */
export async function removeProductFromSelection(
	bulkOrderId: CartId,
	itemId: ProductKey,
	signal?: AbortSignal,
) {
	return (
		await axios.delete<ProductKey[]>(
			`/api/shop/product-selection/${bulkOrderId}/${encodeSlashes(itemId)}`,
			{ signal },
		)
	).data;
}

/**
 * Removes all selected products from the current order.
 * @returns {ProductKey[]} A collection of all selected item IDs.
 */
export async function clearAllProductSelections(bulkOrderId: CartId, signal?: AbortSignal) {
	return (
		await axios.delete<ProductKey[]>(`/api/shop/product-selection/${bulkOrderId}/all`, {
			signal,
		})
	).data;
}

/**
 * Adds or removes a product favorite.
 */
export async function updateProductFavorite(
	itemId: ProductKey,
	isFavorite: boolean,
	signal?: AbortSignal,
) {
	return (
		await axios.post<ProductFavoriteRequest>(
			'/api/shop/products/favorites',
			{
				itemId,
				isFavorite,
			},
			{ signal },
		)
	).data;
}

export function getOrderItemsUri(filter: Partial<OrderItemFilter>): string {
	return axios.getUri({
		url: '/api/shop/order-items',
		params: filter,
	});
}

export async function getOrderItems(filter: Partial<OrderItemFilter>, signal?: AbortSignal) {
	try {
		return (
			await axios.get<OrderItemsResponse>('/api/shop/order-items', {
				params: filter,
				signal,
			})
		).data;
	} catch (error) {
		if (error instanceof ServerError) {
			switch (error.message) {
				case 'RequiresReset':
					throw new ServerError(CartErrorType.noActiveProgramsAndOrProducts);
			}
		}
		throw error;
	}
}

export async function getItemsUpdatedCount(cartId: CartId, signal?: AbortSignal) {
	return (await axios.get<number>(`/api/shop/order-items/updated-count/${cartId}`, { signal }))
		.data;
}

export async function updateItemQuantity(
	bulkOrderId: CartId,
	itemId: ProductKey,
	request: UpdateLineItemQuantityRequest,
	signal?: AbortSignal,
) {
	const result = await axios.post<void>(
		`/api/shop/order-items/${bulkOrderId}/update-quantity/${encodeSlashes(itemId)}`,
		request,
		{ signal, validateStatus: statusCode => statusCode !== 409 },
	);

	if (result.status === 409) {
		throw new ServerError(CartErrorType.quantityUpdateConflict);
	}
}

/** Service wrapper for AppleBrandedPrograms.Web.Features.Shop.ShopShippingOptionsController */
export async function getAvailableShippingOptions(signal?: AbortSignal) {
	return (await axios.get<ShippingOption[]>('/api/shop/shipping-options', { signal })).data;
}

export async function setShippingMethod(
	request: UpdateLocationShippingMethodRequest,
	signal?: AbortSignal,
) {
	return (await axios.post<void>('/api/shop/shipping-options/shipping', request, { signal }))
		.data;
}

export async function saveBulkOrder(request: SaveBulkOrderRequest) {
	return (await axios.post<void>('/api/shop/saved-orders', request)).data;
}

export async function getSavedBulkOrders() {
	return (await axios.get<BulkOrderInfo[]>('/api/shop/saved-orders')).data;
}

export async function setActiveSavedBulkOrder(id: CartId) {
	await axios.patch<void>(`/api/shop/saved-orders/${id}`);
}

export async function deleteSavedBulkOrder(id: CartId): Promise<void> {
	return await axios.delete(`/api/shop/saved-orders/${id}`);
}
export async function getSelectedAddresses(bulkOrderId: CartId, signal?: AbortSignal) {
	return (
		// API returns undefined (204 No Content) if no addresses are selected
		(
			await axios.get<OrderShipToAddress[] | undefined>(
				`/api/shop/address-selection/bulkorder/${bulkOrderId}`,
				{ signal },
			)
		).data ?? []
	);
}

export async function getSelectedAddress(bulkOrderId: CartId, shipToKey: string) {
	return (
		await axios.get<OrderShipToAddress>(
			`/api/shop/address-selection/bulkorder/${bulkOrderId}/location/${shipToKey}`,
		)
	).data;
}

export async function setShippingAddress(address: OrderShipToAddress) {
	await axios.put('/api/shop/address-selection', { ...address });
}

/** Service wrapper for AppleBrandedPrograms.Web.ApiControllers.AppleBrandedBulkOrderApiController */
export async function getOrCreateBulkOrder(signal?: AbortSignal) {
	return (await axios.get<BulkOrder>('/api/shop/order', { signal })).data;
}

export async function getOrderTypes(signal?: AbortSignal) {
	return (await axios.get<OrderReason[]>('/api/shop/order-reasons', { signal })).data;
}

export async function resetBulkOrder(signal?: AbortSignal) {
	await axios.post('/api/shop/reset', { signal });
}

export async function completeOrder(
	bulkOrderId: CartId,
	orderReason: string,
	{
		orderName = null,
		orderComments = null,
		orderNumber = null,
		userAcknowledgedApprovalRequired = false,
		isGhostShipment = false,
		bypassBudget = false,
	} = {} as CompleteRequestOptions,
	signal?: AbortSignal,
): Promise<CompleteResponse> {
	try {
		const response = (
			await axios.post<CompleteBulkOrderResponse>(
				`/api/shop/${bulkOrderId}/complete`,
				{
					orderReason,
					orderName,
					comments: orderComments,
					poNumber: orderNumber,
					orderDate: null,
					acceptedApprovalRequired: userAcknowledgedApprovalRequired,
					isGhostShipment,
					bypassBudget,
				},
				{ signal },
			)
		).data;

		const { completedBulkOrderId, salesOrderNumber, estimatedShipdate } = response;

		return {
			bulkOrderId: completedBulkOrderId,
			salesOrderNumber: salesOrderNumber,
			estimatedShipDate: moment(estimatedShipdate),
		};
	} catch (error) {
		if (error instanceof ServerError) {
			switch (error.message) {
				case 'RequiresReset':
					throw new ServerError(CartErrorType.inactiveProgram);
				case 'RequiresQuantity':
					throw new ServerError(CartErrorType.quantitiesRequired);
				case 'RequiresRefresh':
					throw new ServerError(CartErrorType.shippingAddressMismatch);
				case 'RequiresInput':
					throw new ServerError(CartErrorType.commentRequired);
			}
		}
		throw error;
	}
}
