import { z } from 'zod';

import type { Uri } from '@apple/utils/url';

const httpProblemTypeSchema = z.string().url();

/** @see https://datatracker.ietf.org/doc/html/rfc9457 */
export interface HttpProblemDetails {
	/** A URI reference that identifies the problem type. */
	readonly type: Uri;
	/** A short, human-readable summary of the problem type. */
	readonly title: string;
	/** The HTTP status code generated by the origin server for this occurrence of the problem. */
	readonly status: number;
	/** A human-readable explanation specific to this occurrence of the problem. */
	readonly detail: string;
	/**
	 * A URI reference that identifies the specific occurrence of the problem.
	 * It may or may not yield further information if dereferenced.
	 **/
	readonly instance?: Uri;
}

export const httpProblemDetailsSchema = z.object({
	type: httpProblemTypeSchema,
	title: z.string(),
	status: z.number(),
	detail: z.string(),
	instance: httpProblemTypeSchema.optional(),
});

/** See `Manzanita.Web\Infrastructure\Http\ApiControllerExtensions.cs` - `DialogResponse<T>` */
export type ApiErrorResponse<T> = {
	/** Should be set to a localization key but many of the api's return translated values instead */
	titleKey: string;
	/** Should be set to a localization key but many of the api's return translated values instead */
	contentKey: string;
	/** Should be set to a localization key but many of the api's return translated values instead */
	buttonKeys: string[] | null;
	additionalData: T | null;
};

export function createApiErrorResponseSchema<AdditionalData extends z.ZodTypeAny>(
	additionalDataSchema: AdditionalData,
) {
	return z.object({
		titleKey: z.string(),
		contentKey: z.string(),
		buttonKeys: z.array(z.string()).nullable(),
		additionalData: additionalDataSchema,
	}); // satisfies ApiErrorResponse<AdditionalData>;
}

export type GenericErrorResponse = {
	message: string;
};

export function isApiErrorResponse<T = unknown>(obj: unknown): obj is ApiErrorResponse<T> {
	return !!obj && typeof obj === 'object' && 'titleKey' in obj && 'contentKey' in obj;
}

export function isGenericErrorResponse(obj: unknown): obj is GenericErrorResponse {
	return !!obj && typeof obj === 'object' && 'message' in obj;
}
