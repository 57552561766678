import axios from 'axios';
import { getLogger } from 'loglevel';

const log = getLogger('health-check');

export async function getHealth(): Promise<boolean> {
	try {
		await axios.get('/health');
	} catch (error) {
		log.error('Health check failed.', error);
		throw error;
	}
	return true;
}
