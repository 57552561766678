import { createElement } from 'react';
import { notifications } from '@mantine/notifications';
import axios, { CanceledError } from 'axios';
import log from 'loglevel';
import type { PaginationState as TanstackPaginationState } from '@tanstack/react-table';

import { Error } from '@apple/assets/icons';
import { createODataPageResultSchema, ServerError } from '@apple/utils/api';
import { getPageState } from '@apple/utils/pagination';
import type { PageState } from '@apple/utils/pagination';

import { allowedOrderSearchFiltersSchema, orderSearchListingSchema } from '../models/search';
import type {
	AllowedOrderSearchFilters,
	OrderSearchFilters,
	OrderSearchListing,
} from '../models/search';

const orderSearchODataSchema =
	createODataPageResultSchema<OrderSearchListing>(orderSearchListingSchema);

const unexpectedSearchErrorMessage =
	"We're sorry - an error occurred while fetching your orders. Please try again in a few minutes. Contact support if the problem persists.";

export async function getOrderSearchResults(
	pagination: TanstackPaginationState,
	filters: OrderSearchFilters,
): Promise<PageState<OrderSearchListing>> {
	try {
		const response = await axios.get<unknown>('/api/order-search', {
			params: {
				skip: pagination.pageIndex * pagination.pageSize,
				take: pagination.pageSize,
				filter: filters,
			},
		});

		const result = orderSearchODataSchema.parse(response.data);

		return getPageState<OrderSearchListing>({
			rowSchema: orderSearchListingSchema,
			state: pagination,
			rows: result.items,
			totalRowCount: result.count ?? 0,
		});
	} catch (err) {
		if (err instanceof ServerError && !(err.innerError instanceof CanceledError)) {
			log.error('Error searching orders:', err);

			notifications.show({
				color: 'red',
				message: unexpectedSearchErrorMessage,
				icon: createElement(Error),
			});

			return getPageState<OrderSearchListing>({
				rowSchema: orderSearchListingSchema,
				state: pagination,
				rows: [],
				totalRowCount: 0,
			});
		}
		throw err;
	}
}

export async function getAllowedOrderSearchFields(): Promise<AllowedOrderSearchFilters> {
	const data = (await axios.get<unknown>('/api/order-search/options')).data;
	return allowedOrderSearchFiltersSchema.parse(data);
}

export function getOrderSearchExportUrl() {
	return '/api/order-search/export';
}
