import { useCallback, useState } from 'react';
import { Group, Pagination, Paper, Stack, Title } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';

import { useTranslation } from '@apple/lib/i18next';

import { AnnouncementItem } from './AnnouncementItem';
import type { AnnouncementList } from '../models/announcement';

type UserAnnouncementsProps = {
	announcementList: AnnouncementList;
	markReadOnView?: boolean;
};

export function UserAnnouncements({
	announcementList,
	markReadOnView = true,
}: UserAnnouncementsProps) {
	const { t } = useTranslation('content');
	const queryClient = useQueryClient();
	const announcements = announcementList.announcements.sort((a, b) => {
		if (a.active !== b.active) {
			return Number(b.active) - Number(a.active);
		}
		return b.createdDate.getTime() - a.createdDate.getTime();
	});
	const [announcement, setAnnouncement] = useState(announcements[0]);

	const onAnnouncementChange = useCallback(
		(index: number) => {
			setAnnouncement(announcementList.announcements[index - 1]);
			void queryClient.invalidateQueries({ queryKey: ['active-announcements'] });
		},
		[announcementList.announcements, queryClient],
	);

	return (
		<Stack>
			<Stack justify='flex-end'>
				<Stack justify='flex-end'>
					<Title order={4}>{t('announcements.title')}</Title>
					<Group justify='flex-end'>
						{announcementList.totalCount > 1 && (
							<Pagination
								total={announcementList.totalCount}
								onChange={onAnnouncementChange}
							/>
						)}
					</Group>
				</Stack>
			</Stack>

			{announcement && (
				<Paper withBorder p='sm'>
					<AnnouncementItem announcement={announcement} markReadOnView={markReadOnView} />
				</Paper>
			)}
		</Stack>
	);
}
