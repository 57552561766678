import { z } from 'zod';

export interface Project {
	id: number;
	name: string;
	description: string;
	deleted: boolean;
}

export interface SaveProjectRequest extends Omit<Project, 'id'> {
	id?: number;
}

export type ProjectId = z.infer<typeof projectIdSchema>;
export const projectIdSchema = z.number();

export const projectSchema = z.object({
	id: projectIdSchema,
	name: z.string().min(1),
	description: z.string().min(1),
	deleted: z.boolean(),
}) satisfies z.ZodType<Project>;

export const SaveProjectSchema = projectSchema.extend({
	id: projectSchema.shape.id.optional(),
}) satisfies z.ZodType<SaveProjectRequest>;
