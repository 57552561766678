import { AppShell } from '@mantine/core';

import classes from './appshell.module.css';

export const AppShellTheme = AppShell.extend({
	classNames: {
		root: classes.root,
		header: classes.header,
		main: classes.main,
		footer: classes.footer,
		navbar: classes.navbar,
		aside: classes.aside,
	},
	defaultProps: {
		withBorder: false,
		padding: 0,
	},
	styles: theme => ({
		root: {
			minWidth: theme.other.page.minWidth,
		},
	}),
});
