import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { getEnv } from '@apple/utils/config/env';

export type InitTelemetry = {
	telemetry: ReactPlugin;
};

export let ai: ApplicationInsights | null = null;

export function configAppInsights(options: {
	instrumentationKey: string;
	roleTag: string;
}): InitTelemetry {
	const telemetry = new ReactPlugin();
	const inDev = getEnv().DEV;

	ai = new ApplicationInsights({
		config: {
			connectionString: `instrumentationKey=${options.instrumentationKey}`,
			extensions: [telemetry],
			disableTelemetry: inDev,
			enableDebug: inDev,
			enableAutoRouteTracking: true,
			disableAjaxTracking: false,
			autoTrackPageVisitTime: true,
			enableCorsCorrelation: true,
			enableRequestHeaderTracking: true,
			enableResponseHeaderTracking: true,
			loggingLevelConsole: inDev ? 2 : 1,
		},
	});
	ai.loadAppInsights();
	ai.addTelemetryInitializer(env => {
		env.tags = env.tags || [];
		env.tags['ai.cloud.role'] = options.roleTag;
	});

	return { telemetry } as const;
}
