import axios from 'axios';

import { announcementSchema } from '../models/management';
import type { Announcement } from '../models/management';

export async function getAllAnnouncements(): Promise<Announcement[]> {
	const response = await axios.get<Announcement[]>('/api/management/announcements');

	return announcementSchema.array().parse(response.data);
}

export async function getAnnouncementById(id: string): Promise<Announcement> {
	const response = await axios.get<Announcement>(`/api/management/announcements/${id}`);

	return announcementSchema.parse(response.data);
}

export async function saveAnnouncement(request: Announcement): Promise<void> {
	await axios.put('/api/management/announcements', request);
}

export async function deleteAnnouncement(id: string): Promise<void> {
	await axios.delete(`/api/management/announcements/${id}`);
}
