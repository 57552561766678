import { showNotification } from '@mantine/notifications';
import type { MantineColor } from '@mantine/core';

import type { IconElement } from '@apple/assets/icons';

import { toolbarCommonProps } from './toolbar-button-props';

type Props = {
	tooltip: string;
	icon: IconElement;
	disabled?: boolean;
	loading?: boolean;
	'data-testid'?: string;
	onClick?: () => void;
	color?: MantineColor;
};

export function ToolbarButton({
	tooltip,
	icon: Icon,
	onClick = () => {
		showNotification({
			title: 'Not Implemented',
			message: `'${tooltip}' has not been implemented yet`,
			withBorder: true,
		});
	},
	color = 'blue',
	...rest
}: Props) {
	return (
		<Icon
			size='lg'
			{...toolbarCommonProps}
			{...rest}
			c={color}
			tooltip={tooltip}
			onClick={onClick}
		/>
	);
}
