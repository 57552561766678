import { memo, useCallback } from 'react';
import { Accordion, Box, Checkbox, Group, SimpleGrid, Title } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';

import { Plus } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import type { Plant } from '@apple/features/plants/models';
import type { Program } from '@apple/features/program/models';
import type { CountryCode, CountryWithCultures } from '@apple/utils/globalization';

import classes from './ProductCustomerGroups.module.css';
import { ProgramCountries } from './ProgramCountries';
import type { Product } from '../../models/management';

export const ProductCustomerGroups = memo(
	({
		form,
		programs,
		plants,
		countries,
	}: {
		form: UseFormReturnType<Product>;
		programs: Program[];
		plants: Plant[];
		countries: CountryWithCultures[];
	}) => {
		const { t } = useTranslation('product');
		const { itemId, customerGroupAssociations, customerGroupCountryAssociations } =
			form.getValues();

		const handleChange = useCallback(
			(program: Program, countries: CountryCode[]) => {
				if (countries.length === program.countries.length) {
					form.setFieldValue(
						'customerGroupCountryAssociations',
						customerGroupCountryAssociations.filter(
							x => x.customerGroupId !== program.id,
						),
					);
					if (customerGroupAssociations.some(x => x.customerGroupId === program.id)) {
						return;
					}

					form.setFieldValue('customerGroupAssociations', [
						...customerGroupAssociations,
						{ itemId: itemId, customerGroupId: program.id, isDeleted: false },
					]);
					return;
				}

				if (customerGroupAssociations.some(x => x.customerGroupId === program.id)) {
					form.setFieldValue(
						'customerGroupAssociations',
						customerGroupAssociations.filter(x => x.customerGroupId !== program.id),
					);
				}

				form.setFieldValue('customerGroupCountryAssociations', [
					...customerGroupCountryAssociations.filter(
						x => x.customerGroupId !== program.id,
					),
					...countries.map(x => ({
						customerGroupId: program.id,
						itemId: itemId,
						countryCode: x,
						isDeleted: false,
					})),
				]);
			},
			[form, customerGroupAssociations, customerGroupCountryAssociations, itemId],
		);

		return (
			<Accordion
				classNames={{ chevron: classes.chevron }}
				chevronPosition='left'
				chevron={<Plus />}
			>
				{programs.map((item, i) => (
					<Accordion.Item key={item.id} value={item.name}>
						<Accordion.Control>
							<SimpleGrid cols={{ base: 1, sm: 2, lg: 5 }}>
								<Group gap='xs' justify={'start'}>
									<Title order={4}>{item.name}</Title>
									{customerGroupAssociations.some(
										x => x.customerGroupId === item.id,
									)
										? `(${item.countries.length}/${item.countries.length})`
										: `(${customerGroupCountryAssociations.filter(x => x.customerGroupId === item.id)?.length}/${item.countries.length})`}
								</Group>
								<Group>
									<Box
										onClick={event => {
											event.stopPropagation();
										}}
									>
										<Checkbox
											data-testid={`${item.id} all-available-countries`}
											label={t('associations.labels.allAvailableCountries')}
											checked={customerGroupAssociations.some(
												x => x.customerGroupId === item.id,
											)}
											onChange={event =>
												handleChange(
													item,
													event.currentTarget.checked
														? item.countries
														: [],
												)
											}
										/>
									</Box>
								</Group>
							</SimpleGrid>
						</Accordion.Control>
						<Accordion.Panel>
							<ProgramCountries
								form={form}
								program={item}
								countries={countries}
								onChange={handleChange}
								plants={plants}
							/>
						</Accordion.Panel>
					</Accordion.Item>
				))}
			</Accordion>
		);
	},
);
