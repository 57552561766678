import { queryOptions } from '@tanstack/react-query';

import { getComments, getPurchaseOrder, getPurchaseOrderLines } from '../api/details';

export const purchaseOrderQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber],
		queryFn: ({ signal }) => getPurchaseOrder(poNumber, signal),
		staleTime: 5 * 60 * 1000, // 5 minute
	});

export const purchaseOrderLinesQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber, 'lines'],
		queryFn: ({ signal }) => getPurchaseOrderLines(poNumber, signal),
		staleTime: 5 * 60 * 1000, // 5 minute
	});

export const commentsQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber, 'comments'],
		queryFn: ({ signal }) => getComments(poNumber, signal),
		staleTime: 5 * 60 * 1000, // 5 minute
	});
