import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { createRouter } from '@tanstack/react-router';

import { routeTree } from '@apple/channel/routes.gen';
import { profileQueryOptions } from '@apple/features/auth/queries/profile';
import { ai } from '@apple/lib/app-insights';
import { RouterErrorFallback } from '@apple/ui/error';
import { NotFoundPage } from '@apple/ui/shell/pages/NotFoundPage';
import { NotAuthenticatedError } from '@apple/utils/api/errors';

export type AppleChannelRouter = typeof router;

function isSessionExpired(error: Error): boolean {
	const isNotAuthenticatedError = error instanceof NotAuthenticatedError;
	const isOnAuthRoute =
		router.matchRoute({ to: '/login' }) ||
		router.matchRoute({ to: '/first-login' }) ||
		router.matchRoute({ to: '/reset-password' }) ||
		router.matchRoute({ to: '/session-expired' }) ||
		router.latestLocation.pathname === '/logout' ||
		router.latestLocation.pathname === '/session-expired';

	return isNotAuthenticatedError && !isOnAuthRoute;
}

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (error, query) => {
			if (query.queryKey === profileQueryOptions(true).queryKey) {
				return;
			}

			if (isSessionExpired(error)) {
				void router.navigate({
					to: '/session-expired',
					search: { returnUrl: router.latestLocation.pathname },
				});
				return;
			}

			ai?.trackException({
				exception: error,
				properties: {
					queryKey: query.options.queryKey,
					queryStatus: query.state.status,
					fetchStatus: query.state.fetchStatus,
					fetchFailureReason: query.state.fetchFailureReason,
				},
				severityLevel: 3, // Error
				measurements: {
					dataUpdateCount: query.state.dataUpdateCount,
					dataUpdatedAt: query.state.dataUpdatedAt,
					errorUpdateCount: query.state.errorUpdateCount,
					errorUpdatedAt: query.state.errorUpdatedAt,
					fetchFailureCount: query.state.fetchFailureCount,
				},
			});
		},
	}),
	mutationCache: new MutationCache({
		onError: (error, args, ctx, mutation) => {
			if (!isSessionExpired(error)) {
				ai?.trackException({
					exception: error,
					properties: {
						mutationKey: mutation.options.mutationKey,
						mutationStatus: mutation.state.status,
						submittedAt: mutation.state.submittedAt,
						failureReason: mutation.state.failureReason,
						args,
						ctx,
					},
					severityLevel: 3, // Error
					measurements: {
						failureCount: mutation.state.failureCount,
					},
				});
			}
		},
	}),
	defaultOptions: {
		queries: {
			retry: false,
		},
		mutations: {
			retry: false,
		},
	},
});

export const router = createRouter({
	routeTree,
	defaultPreload: false,
	defaultNotFoundComponent: NotFoundPage,
	defaultErrorComponent: RouterErrorFallback,
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	// https://tanstack.com/router/latest/docs/framework/react/guide/data-loading#passing-all-loader-events-to-an-external-cache
	defaultPreloadStaleTime: 0,
	//Wrap: ({ children }) => <AppProviders>{children}</AppProviders>,
	context: {
		queryClient,
		auth: undefined!, // This will be set after we wrap the app in an AuthProvider
		lang: undefined!, // This will be set after we wrap the app in a LangProvider
	},
});
