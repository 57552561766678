import {
	LeftSidebarCollapse,
	LeftSidebarExpand,
	RightSidebarCollapse,
	RightSidebarExpand,
} from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';
import type { IconElement } from '@apple/assets/icons';

export interface SidebarToggleButtonProps {
	collapsed: boolean;
	onClick: () => void;
	labels?: {
		expand: string;
		collapse: string;
	};
	/** If true, the icon will use the "right" orientation */
	right?: boolean;
	icons?: {
		expand: IconElement;
		collapse: IconElement;
	};
}

export function SidebarToggleButton(props: SidebarToggleButtonProps) {
	const { t } = useTranslation('common');
	return (
		<ToolbarButton
			tooltip={
				props.collapsed
					? (props.labels?.expand ?? t('common:shell.sidebar.expand'))
					: (props.labels?.collapse ?? t('common:shell.sidebar.collapse'))
			}
			icon={
				props.icons
					? props.collapsed
						? props.icons.expand
						: props.icons.collapse
					: props.collapsed
						? props.right
							? RightSidebarExpand
							: LeftSidebarExpand
						: props.right
							? RightSidebarCollapse
							: LeftSidebarCollapse
			}
			onClick={props.onClick}
		/>
	);
}
