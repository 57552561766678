import { Box, rem } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import { CenteredLayout } from '@apple/ui/layouts/CenteredLayout';
import { AppleLogo } from '@apple/ui/shell/components/AppleLogo';
import { getEnv } from '@apple/utils/config/env';

const env = getEnv();

type Props = PropsWithChildren<{
	appName?: string;
	title?: string;
}>;

export function AuthLayout({ appName = env.APPLE_APP_TITLE, title, children }: Props) {
	return (
		<CenteredLayout
			logo={<AppleLogo iconSize={rem(36)} labelSize={rem(28)} label={appName} />}
			title={title}
		>
			<Box w={390}>{children}</Box>
		</CenteredLayout>
	);
}
