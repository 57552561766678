import { Link } from '@mantine/tiptap';
import { TextAlign } from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import type { UseEditorOptions } from '@tiptap/react';

export function useTextEditor(editorOptions: Omit<UseEditorOptions, 'extensions'>) {
	return useEditor({
		...editorOptions,
		extensions: [
			StarterKit,
			Underline,
			Link,
			TextAlign.configure({ types: ['heading', 'paragraph'] }),
		],
	});
}
