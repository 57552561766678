import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getProductVisibilityRoles } from '@apple/features/auth';
import { getProductLines } from '@apple/features/product/api/product-lines';

import { getKeywords } from '../api/keywords';
import { getProduct, getProductAudits, getUnassignedSkus } from '../api/management';

export const productQueryOptions = (itemId: string) =>
	queryOptions({
		queryKey: ['product', itemId],
		queryFn: () => getProduct(itemId),
		staleTime: 5 * 60 * 1000, // 5 minutes
	});

export const productAuditQueryOptions = (itemId: string) =>
	queryOptions({
		queryKey: ['product-audits', itemId],
		queryFn: () => getProductAudits(itemId),
		placeholderData: keepPreviousData,
		staleTime: Infinity,
		initialData: [],
	});

export const keywordsQueryOptions = queryOptions({
	queryKey: ['product-keywords'],
	queryFn: getKeywords,
	staleTime: 5 * 60 * 1000, // 5 minutes
});

export const productVisibilityQueryOptions = queryOptions({
	queryKey: ['product-visibility'],
	queryFn: getProductVisibilityRoles,
	staleTime: Infinity,
});

export const productLinesQueryOptions = queryOptions({
	queryKey: ['product-lines'],
	queryFn: getProductLines,
	staleTime: Infinity,
});

export const unassignedSkusQueryOptions = (enabled: boolean = true) =>
	queryOptions({
		queryKey: ['product', 'unassigned-skus'],
		queryFn: () => getUnassignedSkus(),
		staleTime: 15 * 60 * 1000,
		enabled,
	});
