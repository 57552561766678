import { z } from 'zod';
import type { PaginationState, RowData } from '@tanstack/react-table';

export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_NUMBER = DEFAULT_PAGE_INDEX + 1;
export const DEFAULT_PAGE_SIZE = 25;

/**
 * The unified format for storing a single page's state in the frontend
 * @see PageState
 */
export interface PageState<Row extends RowData> extends PaginationState {
	/** The current page's rows */
	rows: Row[];
	/** The total number of pages in the dataset */
	totalPageCount: number;
	/** The total number of rows in the dataset */
	totalRowCount: number;
}

/**
 * The unified format for storing pagination state in the frontend
 *
 * @see PaginationState
 * @remarks
 * 		Different backend endpoints can use different names for pagination fields.
 * 		Those endpoint's schema's should transform the data into this schema.
 */
export const paginationStateSchema = z.object({
	/** Zero-based page number (first page is 0) */
	pageIndex: z.number().int().nonnegative(), //.default(DEFAULT_PAGE_INDEX).catch(DEFAULT_PAGE_INDEX),
	/** Non-zero-based page number (first page is 1) */
	// pageNumber: z.number().int().positive(), //.default(DEFAULT_PAGE_NUMBER).catch(DEFAULT_PAGE_NUMBER),
	/** Max count of items per page */
	pageSize: z.number().int().positive(), //.default(DEFAULT_PAGE_SIZE).catch(DEFAULT_PAGE_SIZE),
}) satisfies z.ZodType<PaginationState>;

export function pageStateSchema<
	Row extends RowData,
	RowSchema extends z.ZodType<Row> = z.ZodType<Row>,
>(rowSchema: RowSchema): z.ZodType<PageState<Row>> {
	return paginationStateSchema.extend({
		rows: z.array(rowSchema),
		totalPageCount: z.number().int().nonnegative(),
		totalRowCount: z.number().int().nonnegative(),
	}) satisfies z.ZodType<PageState<Row>>;
}

export interface PageStateOptions<
	Row extends RowData,
	RowSchema extends z.ZodType<Row> = z.ZodType<Row>,
> {
	rows: Row[];
	rowSchema: RowSchema;
	state: PaginationState;
	totalRowCount: number;
}

export function getPageState<
	Row extends RowData,
	RowSchema extends z.ZodType<Row> = z.ZodType<Row>,
>(options: PageStateOptions<Row, RowSchema>): PageState<Row> {
	return pageStateSchema<Row, RowSchema>(options.rowSchema).parse({
		...options.state,
		rows: options.rows,
		totalRowCount: options.totalRowCount,
		totalPageCount: Math.ceil(options.totalRowCount / options.state.pageSize),
	});
}

export const DEFAULT_PAGE_STATE = {
	pageIndex: DEFAULT_PAGE_INDEX,
	pageSize: DEFAULT_PAGE_SIZE,
	totalPageCount: 0,
	totalRowCount: 0,
	rows: [],
} as const satisfies PageState<unknown>;

/** @see Manzanita.Collections.PaginatedList<T> */
export interface PaginatedListDto<TData> {
	currentPage: number;
	pageSize: number;
	totalCount: number;
	items: TData[];
}

/** @see Manzanita.Pagination.PaginationResult<T> */
export interface PaginationResult<TData> {
	currentPage: number;
	pageSize: number;
	totalCount: number;
	results: TData[];
}
