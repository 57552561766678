import * as i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpBackend from 'i18next-http-backend';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';

// Adds the type augmentation to i18next
import './types';

import resources from 'virtual:locale-loader';

export type InitLocalizationProps = i18n.InitOptions & {
	module?: i18n.ThirdPartyModule;
	debug?: boolean;
};

// TODO: let the user choose a language
// TODO: load the user's selected language
// TODO: Fix locale hmr (see https://github.com/vitejs/vite/blob/734a9e3a4b9a0824a5ba4a5420f9e1176ce74093/packages/vite/src/node/server/hmr.ts#L124)
/**
 * Configures i18next for use with react-i18next.
 *
 * Examples:
 * https://github.com/i18next/react-i18next/tree/master/example/react/public/locales
 * https://github.com/tooot-app/app/blob/main/src/%40types/i18next.d.ts
 * https://github.com/Kaetram/Kaetram-Open/blob/main/packages/common/i18n/options.ts#L185
 */
export async function configLocalization({
	module = initReactI18next,
	debug = false,
}: InitLocalizationProps) {
	const instance = await i18n
		// detect user language
		// learn more: https://github.com/i18next/i18next-browser-languageDetector
		.use(LanguageDetector)

		// If we want to load locales via an http request. The locales will need to be in the public folder.
		//.use(HttpBackend)

		// bind react-i18next to the i18n instance
		// for all options read: https://www.i18next.com/overview/configuration-options
		.use(module)

		// Initialize i18next
		.init({
			// the translations (tip move them in a JSON file and import them or even better,
			// manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
			resources,
			// lng: 'en', // if you're using a language detector, do not define the lng option
			// load: 'languageOnly', // Right now we dont have any country specific translations, so we only need the language
			fallbackLng: {
				'zh-Hans': ['zh-CN'],
				'zh-Hant': ['zh-TW'],
				default: ['en'],
			},
			ns: ['common'],
			defaultNS: 'common',
			fallbackNS: ['common'],
			debug,
			interpolation: {
				escapeValue: false, // react is already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
			},

			// react i18next special options (optional)
			// override if needed - omit if ok with defaults
			react: {
				bindI18n: 'languageChanged',
				bindI18nStore: '',
				transEmptyNodeValue: '',
				transSupportBasicHtmlNodes: true,
				transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
				useSuspense: true,
			},
		});

	// See node_modules/zod-i18n-map/locales/en/zod.json
	z.setErrorMap(
		makeZodI18nMap({
			t: instance,
			ns: ['zod', 'common'],
			handlePath: false,
		}),
	);

	return instance;
}

// if (import.meta.hot) {
// 	import.meta.hot.on('locales-update', () => {
// 		// Handle locale update, e.g., reload the page or re-fetch translations
// 		console.log('Locales updated');
// 		// location.reload(); // or any other logic to handle the update
// 		void i18n.reloadResources();
// 	});
// }
