/** Get a property name from an object using a case-insensitive lookup */
export function getPropertyNameCaseInsensitive(schema: object, name: string): string | undefined {
	const lookupFieldName = name.toLowerCase();

	const matchedKeys = Object.keys(schema).filter(key => key.toLowerCase() === lookupFieldName);
	if (matchedKeys.length === 0) {
		return undefined;
	}

	if (matchedKeys.length > 1) {
		throw new Error(`Multiple keys found for property name: ${name}`);
	}

	return matchedKeys[0];
}

export function dump<T>(value: T, label?: string): T {
	console.log(label, JSON.stringify(value, null, '\t'));
	return value;
}
