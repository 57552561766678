import { z } from 'zod';

export type Role = z.infer<typeof roleSchema>;
export type RoleName = 'Arvato Manager' | string;
export type ProductVisibilityRoles = z.infer<typeof productVisibilityRolesSchema>;

export const roleSchema = z.object({
	id: z.number(),
	name: z.string(),
});

export const productVisibilityRolesSchema = z.object({
	adminUserRoles: z.array(roleSchema),
	callCenterUserRoles: z.array(roleSchema),
});
