import { Overlay } from '@mantine/core';

export const OverlayTheme = Overlay.extend({
	styles: (theme, props, ctx) => ({
		root: {
			backgroundColor: 'var(--apple-material-under-window-bg-color)',
			backdropFilter: 'var(--apple-material-under-window-bd-filter)',
		},
	}),
});
