import axios from 'axios';

import { locationRequestResponseSchema } from '../models/location';
import { locationApprovalRequestSchema } from '../models/location-approval';
import type { LocationId, LocationRequestResponse } from '../models/location';
import type { LocationApprovalRequest } from '../models/location-approval';

export async function getLocationApprovalQueue(
	signal?: AbortSignal,
): Promise<LocationApprovalRequest[]> {
	const response = await axios.get('/api/location-approval-queue/list', {
		signal,
	});

	return locationApprovalRequestSchema.array().parse(response.data);
}

export async function getLocationAccessRequest(
	userId: number,
	signal?: AbortSignal,
): Promise<LocationRequestResponse> {
	const response = await axios.get(`/api/location-approval-queue/requests/${userId}`, {
		signal,
	});
	return locationRequestResponseSchema.parse(response.data);
}

export async function completeLocationAccessRequests(
	locationIds: LocationId[],
	approve: boolean,
	userId: number,
): Promise<void> {
	await axios.post('/api/location-approval-queue/requests/complete', {
		locationIds,
		approve,
		userId,
	});
}
