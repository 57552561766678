import { useEffect } from 'react';
import { Group, Stack, Title } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import type { MantineStyleProps } from '@mantine/core';

import { useTextEditor } from '@apple/ui/form/hooks/useTextEditor';
import { FormattedDate } from '@apple/utils/globalization';

import { acknowledgeContent } from '../api/announcement';
import classes from './AnnouncementItem.module.css';
import type { Announcement } from '../models/announcement';

type AnnouncementItemProps = MantineStyleProps & {
	announcement: Announcement;
	markReadOnView?: boolean;
};

export function AnnouncementItem({
	announcement,
	markReadOnView,
	...styles
}: AnnouncementItemProps) {
	const editor = useTextEditor({
		content: announcement.content,
		editable: false,
	});

	useEffect(() => {
		if (editor && editor.getHTML() !== announcement.content) {
			editor.commands.setContent(announcement.content);
		}

		if (!announcement.acknowledged && markReadOnView) {
			void acknowledgeContent(announcement.contentId);
		}
	}, [
		announcement.acknowledged,
		announcement.content,
		announcement.contentId,
		editor,
		markReadOnView,
	]);

	return (
		<Stack {...styles}>
			<Group justify='space-between'>
				<Title order={4}>{announcement.title}</Title>
				<FormattedDate data-testId='created-date' value={announcement.createdDate} />
			</Group>
			{editor && (
				<RichTextEditor editor={editor} className={classes.textEditor}>
					<RichTextEditor.Content />
				</RichTextEditor>
			)}
		</Stack>
	);
}
