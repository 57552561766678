import { Box, Center, Group, Paper, rem, Stack, Title } from '@mantine/core';
import type React from 'react';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
	logo?: React.ReactNode;
	title?: string | React.ReactNode;
}>;

export function CenteredLayout({ logo, title, children }: Props) {
	return (
		<Center mt={rem(96)}>
			<Paper
				p='xl'
				shadow='xl'
				radius='xl'
				styles={{
					root: {
						boxShadow: '0 11px 34px 0 var(--apple-color-box-shadow)',
					},
				}}
			>
				<Stack gap={0} justify='space-between' align='center'>
					{logo}
					{!title ? null : (
						<Group justify='center'>
							{typeof title === 'string' ? (
								<Title order={4} ta='center' mt={logo ? 'lg' : undefined}>
									{title}
								</Title>
							) : (
								title
							)}
						</Group>
					)}
					<Box p={20} mt='sm'>
						{children}
					</Box>
				</Stack>
			</Paper>
		</Center>
	);
}
