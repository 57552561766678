import type { AxiosError } from 'axios';

import type { ValidationProblemDetails } from '@apple/utils/validation';

import type { HttpProblemDetails } from './models';

export interface ServerErrorOptions extends ErrorOptions {
	innerError?: unknown;
}

export class ServerError extends Error {
	constructor(
		message: string,
		protected options?: ServerErrorOptions,
	) {
		super(message, options);
	}

	get innerError(): unknown {
		return this.options?.innerError;
	}
}

export class NotAuthenticatedError extends ServerError {
	constructor(error: AxiosError) {
		super('Not authenticated.', error);
	}
}

export class NotAuthorizedError extends ServerError {
	constructor(error: AxiosError) {
		super('Not authorized.', error);
	}
}

export class HttpProblemDetailsError extends ServerError {
	constructor(
		error: Error,
		public readonly problemDetails: HttpProblemDetails,
	) {
		super('An error occured', error);
	}
}

export class ServerProblemError extends ServerError {
	constructor(
		error: Error,
		public readonly problem: HttpProblemDetails,
	) {
		super('An error occured', error);
	}
}

export class ServerValidationError<T> extends ServerProblemError {
	constructor(
		error: Error,
		public readonly problem: ValidationProblemDetails<T>,
	) {
		super(error, problem);
	}
}
