import { Indicator } from '@mantine/core';

export const IndicatorTheme = Indicator.extend({
	defaultProps: {
		size: 16,
	},
	styles: theme => ({
		indicator: {
			fontFamily: theme.other.fonts.caption2.fontFamily,
			fontSize: theme.other.fonts.caption2.fontSize,
			fontStyle: theme.other.fonts.caption2.fontStyle,
			fontWeight: theme.other.fonts.caption2.fontWeightRegular,
			lineHeight: theme.other.fonts.caption2.lineHeight,
			letterSpacing: theme.other.fonts.caption2.letterSpacing,
		},
	}),
});
