import { useComputedColorScheme } from '@mantine/core';

export function useColorScheme() {
	const colorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

	return {
		colorScheme,
		isDark: colorScheme === 'dark',
		isLight: colorScheme === 'light',
	};
}
