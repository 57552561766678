import { useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from '@mantine/hooks';

import { REMIND_ME_LATER_LOCAL_STORAGE_KEY } from '@apple/features/announcements/consts';
import { useAcknowledgeAnnouncement } from '@apple/features/announcements/hooks/useAcknowledgeAnnouncement';
import { useTranslation } from '@apple/lib/i18next';
import { useLogger } from '@apple/utils/logging/useLogger';

import { openAnnouncementModal } from './AnnouncementModal';
import type { AnnouncementList } from '../models/announcement';

type ActiveAnnouncementsProps = {
	announcementList: AnnouncementList;
};

export function ActiveAnnouncements({ announcementList }: ActiveAnnouncementsProps) {
	const { t } = useTranslation('announcement');
	const acknowledgeAnnouncement = useAcknowledgeAnnouncement();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [remindLaterIds, setRemindLaterIds] = useLocalStorage<number[]>({
		key: REMIND_ME_LATER_LOCAL_STORAGE_KEY,
		defaultValue: [],
		getInitialValueInEffect: false,
	});

	const announcementsToShow = useMemo(
		() =>
			announcementList.announcements.filter(
				announcement =>
					announcement.active &&
					!announcement.acknowledged &&
					!remindLaterIds.includes(announcement.contentId),
			),
		[announcementList.announcements, remindLaterIds],
	);

	useLogger({
		log: 'announcement',
		name: 'ActiveAnnouncements',
		props: [{ announcementList, currentIndex, remindLaterIds }],
	});

	useEffect(() => {
		const firstAnnouncement = announcementsToShow.find(
			announcement => !remindLaterIds.includes(announcement.contentId),
		);

		if (firstAnnouncement) {
			openAnnouncementModal({
				t,
				announcement: firstAnnouncement,
				onAcknowledge: () => {
					const announcement = announcementsToShow[currentIndex]!;
					acknowledgeAnnouncement.mutate(announcement.contentId, {
						onSuccess: () => {
							if (currentIndex + 1 < announcementsToShow.length) {
								setCurrentIndex(prevIndex => prevIndex + 1);
							} else {
								setCurrentIndex(0);
							}
						},
					});
				},
				onRemindMeLater: () => {
					const announcement = announcementsToShow[currentIndex]!;
					setRemindLaterIds(prevIds => [...prevIds, announcement.contentId]);

					if (currentIndex + 1 < announcementsToShow.length) {
						setCurrentIndex(prevIndex => prevIndex + 1);
					} else {
						setCurrentIndex(0);
					}
				},
			});
		}
	}, [
		acknowledgeAnnouncement,
		announcementsToShow,
		currentIndex,
		remindLaterIds,
		setRemindLaterIds,
		t,
	]);

	return null;
}
