import { z } from 'zod';

/**
 * These values are passed to the snapshot odata api's via the url to apply additional custom server-side filtering.
 * @see Manzanita.Web.Features.Snapshot.SnapshotPerspectiveFilters */
export interface CommonFilters {
	warehouseId?: number | null | undefined;
	countryCode?: string | null | undefined;
	customerGroupId?: number | null | undefined;
	customerId?: number | null | undefined;
	productCategoryId?: number | null | undefined;
	userRoleId?: number | null | undefined;
	userId?: number | null | undefined;
	allAssociationsOnly?: boolean | null | undefined;
}

/**
 * These values are passed to the snapshot odata api's via the url to apply additional custom server-side filtering.
 * @see Manzanita.Web.Features.Snapshot.SnapshotPerspectiveFilters */
export const commonFiltersSchema = z.object({
	warehouseId: z.onumber().nullable(),
	countryCode: z.ostring().nullable(),
	customerGroupId: z.onumber().nullable(),
	customerId: z.onumber().nullable(),
	productCategoryId: z.onumber().nullable(),
	userRoleId: z.onumber().nullable(),
	userId: z.onumber().nullable(),
	allAssociationsOnly: z.oboolean().nullable(),
}) satisfies z.ZodType<CommonFilters> as z.ZodType<CommonFilters>;
