import axios from 'axios';
import type { AxiosProgressEvent } from 'axios';

import { plantSchema } from '@apple/features/plants';
import { encodeSlashes } from '@apple/utils/url';
import type { Plant } from '@apple/features/plants';

import {
	endOfLifeReplacementSchema,
	inventorySchema,
	productAuditSchema,
	productExcelResultSchema,
	productSchema,
	warehousePriceSchema,
} from '../models/management';
import type {
	EndOfLifeReplacement,
	Inventory,
	Product,
	ProductAudit,
	ProductExcelResult,
	ProductImageUploadResponse,
	WarehousePrice,
} from '../models/management';
import type { ProductKey } from '../models/product';

/** Service wrapper for:
 * - AppleBrandedPrograms.Web.Features.Catalog.AppleBrandedProductsController
 * - Manzanita.Web.Features.Catalog.ProductsController
 * - Manzanita.Web.Features.Catalog.ProductImagesController
 */

/** Gets the product with the given ID. */
export async function getProduct(itemId: ProductKey): Promise<Product> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}`);
	return productSchema.parse(response.data);
}

/**
 * Saves a new or existing product with the given ID and information.
 * @param product - The product to save.
 * @param auditComment - Optional. Only required if the audit filter is enabled on the server.
 */
export async function saveProduct(product: Product, auditComment?: string): Promise<void> {
	await axios.put(`/api/management/products/${encodeSlashes(product.itemId)}`, product, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/**
 * Saves a collection of new, existing, or deleted products.
 * @param products - The products to save.
 * @param auditComment - Optional. Only required if the audit filter is enabled on the server.
 */
export async function saveProducts(products: Product[], auditComment?: string): Promise<void> {
	await axios.post('/api/management/products', products, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/** Deletes the product with the given ID. */
export async function deleteProduct(itemId: ProductKey, auditComment?: string): Promise<void> {
	await axios.delete(`/api/management/products/${encodeSlashes(itemId)}`, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/** Deletes the products with the given IDs. */
export async function deleteProducts(itemIds: ProductKey[], auditComment?: string): Promise<void> {
	if (itemIds.length === 0) {
		return;
	}

	await axios.post('/api/management/products/delete-multiple', itemIds, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/** Gets current inventory information for the given item. */
export async function getInventory(itemId: string): Promise<Inventory[]> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}/inventory`);

	return inventorySchema.array().parse(response.data);
}

/** Gets current price information for the given item. */
export async function getPrices(itemId: string): Promise<WarehousePrice[]> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}/prices`);

	return warehousePriceSchema.array().parse(response.data);
}

/** Gets all end of life records where the replacement SKU belongs to the given item. */
export async function getEolReplacementRecords(itemId: string): Promise<EndOfLifeReplacement[]> {
	const response = await axios.get(
		`/api/management/products/${encodeSlashes(itemId)}/eol-replacements`,
	);

	return endOfLifeReplacementSchema.array().parse(response.data);
}

/** Gets all plants available per inventory. */
export async function getPlantsWithInventory(itemId: string): Promise<Plant[]> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}/plants`);

	return plantSchema.array().parse(response.data);
}

/** Gets all SKUs in the system that are not assigned to a product in the catalog. */
export async function getUnassignedSkus(): Promise<ProductKey[]> {
	const response = await axios.get<ProductKey[]>(
		'/api/management/products/inventory/unassigned-skus',
	);
	return response.data;
}

/** This method is implemented in the client projects, but the URL is the same in both projects. */
export async function getProductAudits(itemId: string): Promise<ProductAudit[]> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}/audits`);
	return productAuditSchema.array().parse(response.data);
}

/** Uploads product image */
export async function uploadProductImage(
	file: File,
	itemId: string,
): Promise<ProductImageUploadResponse> {
	const body = new FormData();
	body.append('file0', file);
	body.append('itemId', itemId);

	const response = await axios.post<ProductImageUploadResponse>(
		'/api/management/products/images',
		body,
	);

	return response.data;
}

/**
 * Uploads a product bulk management Excel file to be converted into products.
 */
export async function uploadProducts(
	file: File,
	onUploadProgress?: (event: AxiosProgressEvent) => void,
): Promise<ProductExcelResult> {
	const response = await axios.post<unknown>('/api/management/products/bulk/excel', file, {
		headers: { 'Content-Type': file.type },
		onUploadProgress,
	});

	return productExcelResultSchema.parse(response.data);
}

export function getProductManagementTemplateUrl(): string {
	return '/api/management/products/bulk/excel/generate';
}
