import { cloneElement } from 'react';
import { Anchor, Button, Flex, Text } from '@mantine/core';
import { createLink } from '@tanstack/react-router';
import type { ToOptions } from '@tanstack/react-router';
import type { HTMLAttributeAnchorTarget } from 'react';

import classes from './button-with-icon.module.css';

type CustomButtonIconProps = ToOptions & {
	icon: React.ReactNode;
	text: string;
	backgroundColor?: string;
	datatestId?: string;
	href?: string;
	target?: HTMLAttributeAnchorTarget;
};

const CustomIconButton: React.FC<CustomButtonIconProps> = ({
	icon,
	text,
	backgroundColor,
	datatestId,
	href,
	target,
	...toOptions
}) => {
	const content = (
		<>
			<Flex className={classes.icon}>{icon && cloneElement(icon as React.ReactElement)}</Flex>
			<Text className={classes.text}>{text}</Text>
		</>
	);

	return href ? (
		<Anchor target={target} href={href} underline='never'>
			<Button
				data-testid={datatestId}
				className={classes.button}
				bg={backgroundColor}
				children={content}
			/>
		</Anchor>
	) : (
		<LinkButton
			data-testid={datatestId}
			className={classes.button}
			bg={backgroundColor}
			component='button'
			classNames={content}
			{...toOptions}
			children={content}
		/>
	);
};

const LinkButton = createLink(Button);

export default CustomIconButton;
