import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getPrograms } from './api';
import type { ProgramFilters } from './models';

export const programsQueryOptions = (filters?: ProgramFilters) =>
	queryOptions({
		queryKey: ['programs', filters],
		queryFn: ({ signal }) => getPrograms(filters, signal),
		placeholderData: keepPreviousData,
		staleTime: 5 * 60 * 1000, // 5 minutes
	});
