import { z } from 'zod';

export interface MerchandiserFilters extends Partial<MerchandiserListing> {}

export interface MerchandiserListing {
	id: string;
	merchandiserName: string;
	appleId: string;
	countryName: string;
}

export const merchandiserListingSchema = z.object({
	id: z.string(),
	merchandiserName: z.string(),
	appleId: z.string(),
	countryName: z.string(),
}) satisfies z.ZodType<MerchandiserListing>;

export const merchandiserFiltersSchema =
	merchandiserListingSchema.partial() satisfies z.ZodType<MerchandiserFilters>;
