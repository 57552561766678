import { useEffect } from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import type { ErrorRouteComponent } from '@tanstack/react-router';

import { ErrorFallback } from './ErrorFallback';

export const RouterErrorFallback: ErrorRouteComponent = ({ error, reset, info }) => {
	const router = useRouter();
	const queryErrorResetBoundary = useQueryErrorResetBoundary();

	useEffect(() => {
		// Reset the query error boundary
		queryErrorResetBoundary.reset();
	}, [queryErrorResetBoundary]);

	return (
		<ErrorFallback
			error={error}
			componentStack={info?.componentStack}
			onReset={() => {
				// Reset the router error boundary
				reset();
				// Invalidate the route to reload the loader
				void router.invalidate();
			}}
		/>
	);
};
