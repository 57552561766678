import { queryOptions } from '@tanstack/react-query';

import type { ServerError } from '@apple/utils/api';
import type { CountryCode } from '@apple/utils/globalization';
import type { CharacterWhitelist } from '@apple/utils/validation';

import {
	getAddressCharacterWhitelist,
	getAddressRules,
	getValidationCharacterWhitelist,
	getValidationFormRules,
} from './api';
import type { ValidationFormFieldOptions } from './api';

export const validationFormRulesQueryOptions = (options: ValidationFormFieldOptions) =>
	queryOptions({
		queryKey: ['validation', 'form-fields', options.validationType, options.countryCode],
		queryFn: ({ signal }) => getValidationFormRules(options, signal),
		// This data almost never changes, so we can cache it indefinitely (for the user's session)
		staleTime: Infinity,
		gcTime: Infinity,
		refetchOnMount: false,
		refetchInterval: false,
		refetchIntervalInBackground: false,
		throwOnError: true,
	});

export const validationCharacterWhitelistQueryOptions = () =>
	queryOptions<CharacterWhitelist, ServerError>({
		queryKey: ['validation', 'character-whitelist'],
		queryFn: ({ signal }) => getValidationCharacterWhitelist(signal),
		// This data almost never changes, so we can cache it indefinitely (for the user's session)
		staleTime: Infinity,
		gcTime: Infinity,
		refetchOnMount: false,
		refetchInterval: false,
		refetchIntervalInBackground: false,
		throwOnError: true,
	});

export const validationAddressRulesQueryOptions = (countryCode: CountryCode) =>
	queryOptions({
		queryKey: ['validation', 'address-form-fields', countryCode],
		queryFn: ({ signal }) => getAddressRules(countryCode, signal),
		// This data almost never changes, so we can cache it indefinitely (for the user's session)
		staleTime: Infinity,
		gcTime: Infinity,
		refetchOnMount: false,
		refetchInterval: false,
		refetchIntervalInBackground: false,
		throwOnError: true,
	});

export const validationAddressCharacterWhitelistQueryOptions = (countryCode: CountryCode) =>
	queryOptions<CharacterWhitelist, ServerError>({
		queryKey: ['validation', 'address-character-whitelist', countryCode],
		queryFn: ({ signal }) => getAddressCharacterWhitelist(countryCode, signal),
		// This data almost never changes, so we can cache it indefinitely (for the user's session)
		staleTime: Infinity,
		gcTime: Infinity,
		refetchOnMount: false,
		refetchInterval: false,
		refetchIntervalInBackground: false,
		throwOnError: true,
	});
