import { memo } from 'react';
import { Text } from '@mantine/core';
import type { TextProps } from '@mantine/core';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

type FormattedBooleanProps = {
	value: boolean;
	size?: TextProps['size'];
	span?: boolean;
	useIcon?: boolean;
};

export const FormattedBoolean = memo(_FormattedBoolean);
FormattedBoolean.displayName = 'FormattedBoolean';

function _FormattedBoolean({ value, size, span, useIcon }: FormattedBooleanProps) {
	const { t } = useTranslation('common');

	if (useIcon) {
		return value ? <icons.Success color='green' /> : <icons.Close color='red' />;
	}

	return (
		<Text size={size} span={span}>
			{value ? t('label.yes') : t('label.no')}
		</Text>
	);
}
