import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

export interface Email {
	id: string;
	subject: string;
	recipient: string;
	recipientCC: string | null;
	recipientBCC: string | null;
	htmlBody: string;
}

export interface EmailFilters
	extends Partial<Omit<EmailListing, 'id' | 'locked' | 'createdOn' | 'sentOn'>> {
	createdOn?: [string, string] | null;
	sentOn?: [string, string] | null;
}

export interface EmailListing {
	id: string;
	subject: string;
	recipient: string;
	createdOn: Date;
	sentOn: Date | null;
	locked: boolean;
}

export const emailSchema = z.object({
	id: z.string(),
	subject: z.string(),
	recipient: z.string(),
	recipientCC: z.string().nullable(),
	recipientBCC: z.string().nullable(),
	htmlBody: z.string(),
});

const emailListingShape = z.object({
	id: z.string(),
	subject: z.string(),
	recipient: z.string(),
	createdOn: dateTimeSchema,
	sentOn: dateTimeSchema.nullable(),
	locked: z.boolean(),
});

export const emailListingSchema = emailListingShape as z.ZodType<EmailListing>;

export const emailFiltersSchema = emailListingShape.partial().extend({
	createdOn: z.tuple([z.string(), z.string()]).optional().nullable(),
	sentOn: z.tuple([z.string(), z.string()]).optional().nullable(),
}) satisfies z.ZodType<EmailFilters>;
