import { z } from 'zod';

export interface SortedImage {
	smallImageUrl: string;
	largeImageUrl: string;
	sortOrder: number;
}

export const sortedImageSchema = z.object({
	smallImageUrl: z.string(),
	largeImageUrl: z.string(),
	sortOrder: z.number(),
}) satisfies z.ZodType<SortedImage> as z.ZodType<SortedImage>;
