import { Text } from '@mantine/core';
import type { TextProps } from '@mantine/core';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<
	TextProps & {
		bold?: boolean;
	}
>;

export function Footnote({ children, bold, ...props }: Props) {
	return (
		<Text
			{...props}
			style={theme => ({
				fontFamily: theme.other.fonts.footnote.fontFamily,
				fontSize: theme.other.fonts.footnote.fontSize,
				fontWeight: bold
					? theme.other.fonts.footnote.fontWeightBold
					: theme.other.fonts.footnote.fontWeightRegular,
				fontStyle: theme.other.fonts.footnote.fontStyle,
				lineHeight: theme.other.fonts.footnote.lineHeight,
				letterSpacing: theme.other.fonts.footnote.letterSpacing,
			})}
		>
			{children}
		</Text>
	);
}
