import { memo, useMemo } from 'react';
import { Text } from '@mantine/core';
import type { TextProps } from '@mantine/core';

import { DEFAULT_CULTURE } from '../models';
import type { CurrencyCode } from '../models';

type FormattedPriceProps = {
	value?: number | null;
	currency: CurrencyCode;
	// showCurrencySymbol?: boolean;
	hideCurrencyCode?: boolean;
	size?: TextProps['size'];
	span?: boolean;
};

export const FormattedPrice = memo(_FormattedPrice);
FormattedPrice.displayName = 'FormattedPrice';

function _FormattedPrice({
	value,
	currency,
	// showCurrencySymbol,
	hideCurrencyCode = false,
	size,
	span,
}: FormattedPriceProps) {
	const formatter = useMemo(
		() =>
			new Intl.NumberFormat(DEFAULT_CULTURE, {
				style: 'currency',
				currency,
			}),
		[currency],
	);

	const formattedPrice = useMemo(() => formatter.format(value ?? 0), [formatter, value]);

	if (value === undefined || value === null) {
		return null;
	}

	return (
		<Text size={size} span={span}>
			{formattedPrice}
			{!hideCurrencyCode && ` ${currency}`}
		</Text>
	);
}
