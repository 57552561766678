import { Title } from '@mantine/core';
import type { TitleProps } from '@mantine/core';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<
	TitleProps & {
		bold?: boolean;
		sub?: boolean;
	}
>;

export function Headline({ children, bold, sub, ...props }: Props) {
	return (
		<Title
			{...props}
			style={theme =>
				sub
					? {
							fontFamily: theme.other.fonts.subheadline.fontFamily,
							fontSize: theme.other.fonts.subheadline.fontSize,
							fontWeight: bold
								? theme.other.fonts.subheadline.fontWeightBold
								: theme.other.fonts.subheadline.fontWeightRegular,
							fontStyle: theme.other.fonts.subheadline.fontStyle,
							lineHeight: theme.other.fonts.subheadline.lineHeight,
							letterSpacing: theme.other.fonts.subheadline.letterSpacing,
						}
					: {
							fontFamily: theme.other.fonts.headline.fontFamily,
							fontSize: theme.other.fonts.headline.fontSize,
							fontWeight: bold
								? theme.other.fonts.headline.fontWeightBold
								: theme.other.fonts.headline.fontWeightRegular,
							fontStyle: theme.other.fonts.headline.fontStyle,
							lineHeight: theme.other.fonts.headline.lineHeight,
							letterSpacing: theme.other.fonts.headline.letterSpacing,
						}
			}
		>
			{children}
		</Title>
	);
}
