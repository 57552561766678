import axios from 'axios';

import { countryWithCultures } from './models';
import type {
	CountryOption,
	CountryWithCultures,
	CurrencyOption,
	LanguageOption,
	TimeZoneOption,
} from './models';

/**
 * @see Manzanita.Web.Features.Currencies.CurrencyController
 * @see Manzanita.Web.ApiControllers.GlobalizationController
 */

const UnexpectedErrorMessage = 'An unexpected error occurred while calling the globalization API';

export async function getTimeZoneOptions(): Promise<TimeZoneOption[]> {
	try {
		const response = await axios.get<TimeZoneOption[]>('/api/globalization/time-zones');
		return response.data;
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}

export async function getCountryOptions(): Promise<CountryOption[]> {
	try {
		const response = await axios.get<CountryOption[]>('/api/globalization/countries');
		return response.data;
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}

export async function getCountriesWithCultures(
	checkForPlantEnabled: boolean = false,
): Promise<CountryWithCultures[]> {
	const data = (await axios.get<unknown>('/api/countries', { params: { checkForPlantEnabled } }))
		.data;
	return countryWithCultures.array().parse(data);
}

export async function getSupportedCurrencyOptions(): Promise<CurrencyOption[]> {
	try {
		const response = await axios.get<CurrencyOption[]>('/api/globalization/currencies');
		return response.data;
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}

export async function getSupportedCurrencies(): Promise<CurrencyOption[]> {
	try {
		const response = await axios.get<CurrencyOption[]>('/api/currencies');
		return response.data;
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}

export async function getLanguageOptions(): Promise<LanguageOption[]> {
	try {
		const response = await axios.get<LanguageOption[]>('/api/globalization/languages');
		return response.data;
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}
