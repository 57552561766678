import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import type { OdataQueryBuilder } from 'odata-builder';

import { createDataTableQueryOptionsSchema } from '@apple/ui/data-table';
import { escapeODataFilterValue, execODataQuery } from '@apple/utils/api';
import { DEFAULT_PAGE_STATE } from '@apple/utils/pagination/models';
import type { DataTableQueryOptions, FilterData } from '@apple/ui/data-table';

import { productFiltersSchema, productListingSchema } from '../models/listing';
import type { ProductFilters, ProductListing } from '../models/listing';

export const PRODUCTS_ODATA_QUERY_KEY = 'products-odata';

const productQueryOptionsSchema =
	createDataTableQueryOptionsSchema<ProductFilters>(productFiltersSchema);

export function getProductDataTableQueryOptions<TFilters extends FilterData<TFilters>>(
	options: DataTableQueryOptions<TFilters>,
) {
	const { filters, pagination } = productQueryOptionsSchema.parse(options);
	return queryOptions({
		queryKey: [PRODUCTS_ODATA_QUERY_KEY, filters, pagination],
		queryFn: async ({ signal }) =>
			await execODataQuery<ProductListing>({
				urlPath: '/odata/management/products',
				rowSchema: productListingSchema,
				builderFn: odataBuilder => buildProductODataQuery(odataBuilder, options.filters),
				pagination,
				// TODO: The common/perspective filters should be settable by the frontend filters
				// commonFilters: {
				// 	  countryCode: 'US',
				// },
				signal,
			}),
		placeholderData: keepPreviousData,
		throwOnError: true,
		// gcTime: 5 * 60 * 1000, // 5 minute
		// staleTime: 30 * 1000, // 30 seconds
		retry: false,
		initialData: DEFAULT_PAGE_STATE,
	});
}

// TODO: Find a better way to map filters and sorting to the odata builder
function buildProductODataQuery(
	builder: OdataQueryBuilder<ProductListing>,
	filters: ProductFilters,
) {
	builder.orderBy({
		field: 'productName',
		orderDirection: 'asc',
	});

	if (filters.isEnabledInErp !== undefined) {
		builder.filter({
			field: 'isEnabledInErp',
			operator: 'eq',
			value: filters.isEnabledInErp,
		});
	}

	if (filters.isEndOfLifeComplete !== undefined) {
		builder.filter({
			field: 'isEndOfLifeComplete',
			operator: 'eq',
			value: filters.isEndOfLifeComplete,
		});
	}

	if (filters.isActive !== undefined) {
		builder.filter({
			field: 'isActive',
			operator: 'eq',
			value: filters.isActive,
		});
	}

	if (filters.partNumber !== undefined) {
		builder.filter({
			field: 'sku',
			operator: 'contains',
			value: escapeODataFilterValue(filters.partNumber || ''),
			ignoreCase: true,
		});
	}

	if (filters.name !== undefined) {
		builder.filter({
			field: 'productName',
			operator: 'contains',
			value: escapeODataFilterValue(filters.name || ''),
			ignoreCase: true,
		});
	}

	if (filters.warehouseCode !== undefined) {
		builder.filter({
			field: 'warehouseCode',
			operator: 'eq',
			value: filters.warehouseCode,
		});
	}

	if (filters.requiresApproval === true) {
		builder.filter({
			field: 'requiresApproval',
			operator: 'eq',
			value: true,
		});
	}

	if (filters.quantityLimit !== undefined && Array.isArray(filters.quantityLimit)) {
		const [minQuantity, maxQuantity] = filters.quantityLimit;
		if (minQuantity !== undefined) {
			builder.filter({
				field: 'quantityLimit',
				operator: 'ge',
				value: minQuantity,
			});
		}
		if (maxQuantity !== undefined) {
			builder.filter({
				field: 'quantityLimit',
				operator: 'le',
				value: maxQuantity,
			});
		}
	}

	if (filters.hasQuantityLimit !== undefined) {
		if (filters.hasQuantityLimit === 'yes') {
			builder.filter({
				field: 'quantityLimit',
				operator: 'ge',
				value: 0,
			});
		} else {
			builder.filter({
				field: 'quantityLimit',
				operator: 'eq',
				value: null,
			});
		}
	}

	if (filters.availableQuantity !== undefined && Array.isArray(filters.availableQuantity)) {
		const [minQuantity, maxQuantity] = filters.availableQuantity;
		if (minQuantity !== undefined) {
			builder.filter({
				field: 'availableQuantity',
				operator: 'ge',
				value: minQuantity,
			});
		}
		if (maxQuantity !== undefined) {
			builder.filter({
				field: 'availableQuantity',
				operator: 'le',
				value: maxQuantity,
			});
		}
	}

	if (filters.availability !== undefined) {
		if (filters.availability === 'inStock') {
			builder.filter({
				field: 'availableQuantity',
				operator: 'gt',
				value: 0,
			});
		} else if (filters.availability === 'outOfStock') {
			builder.filter({
				field: 'availableQuantity',
				operator: 'le',
				value: 0,
			});
		}
	}

	if (filters.visibility !== undefined) {
		builder.filter({
			field: 'visibility',
			operator: 'eq',
			value: filters.visibility,
		});
	}

	if (filters.isBackorderable === false) {
		builder.filter({
			field: 'isBackorderable',
			operator: 'eq',
			value: false,
		});
	}

	if (filters.hasImages === false) {
		builder.filter({
			field: 'hasImages',
			operator: 'eq',
			value: false,
		});
	}
}
