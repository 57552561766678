import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import moment from 'moment';
import type { OdataQueryBuilder } from 'odata-builder';

import { createDataTableQueryOptionsSchema } from '@apple/ui/data-table';
import { DEFAULT_ODATA_PAGE_STATE, execODataQuery } from '@apple/utils/api';
import type { DataTableQueryOptions } from '@apple/ui/data-table';

import { emailFiltersSchema, emailListingSchema } from '../models/listing';
import type { EmailFilters, EmailListing } from '../models/listing';

const emailQueryOptionsSchema = createDataTableQueryOptionsSchema<EmailFilters>(emailFiltersSchema);

export function getEmailReportDataTableQueryOptions(options: DataTableQueryOptions<EmailFilters>) {
	const { pagination, filters } = emailQueryOptionsSchema.parse(options);
	return queryOptions({
		queryKey: ['emails-odata', filters, pagination],
		queryFn: async ({ signal }) =>
			await execODataQuery<EmailListing>({
				urlPath: '/api/EmailReportApi',
				rowSchema: emailListingSchema,
				builderFn: odataBuilder =>
					buildEmailReportODataQuery(odataBuilder, options.filters),
				pagination,
				signal,
			}),
		placeholderData: keepPreviousData,
		throwOnError: true,
		retry: false,
		initialData: DEFAULT_ODATA_PAGE_STATE,
	});
}

// TODO: Find a better way to map filters and sorting to the odata builder
function buildEmailReportODataQuery(
	builder: OdataQueryBuilder<EmailListing>,
	filters: EmailFilters,
) {
	builder.orderBy({
		field: 'createdOn',
		orderDirection: 'desc',
	});

	if (filters.recipient !== undefined) {
		builder.filter({
			field: 'recipient',
			operator: 'contains',
			ignoreCase: true,
			value: encodeURIComponent(filters.recipient),
		});
	}

	if (filters.subject !== undefined) {
		builder.filter({
			field: 'subject',
			operator: 'contains',
			ignoreCase: true,
			value: filters.subject,
		});
	}

	if (filters.createdOn !== undefined && Array.isArray(filters.createdOn)) {
		const [startDate, endDate] = filters.createdOn;

		builder.filter({
			field: 'createdOn',
			operator: 'ge',
			value: moment(startDate).startOf('day').toDate(),
		});

		builder.filter({
			field: 'createdOn',
			operator: 'le',
			value: moment(endDate).endOf('day').toDate(),
		});
	}

	if (filters.sentOn !== undefined && filters.sentOn != null && Array.isArray(filters.sentOn)) {
		const [startDate, endDate] = filters.sentOn;

		builder.filter({
			field: 'sentOn',
			operator: 'ge',
			value: moment(startDate).startOf('day').toDate(),
		});

		builder.filter({
			field: 'sentOn',
			operator: 'le',
			value: moment(endDate).endOf('day').toDate(),
		});
	}
}
