import { useComputedColorScheme, useMantineColorScheme } from '@mantine/core';

import { DarkModeIcon, LightModeIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import type { IconElementProps } from '@apple/assets/icons';

const commonIconProps = {
	variant: 'transparent',
	c: 'var(--mantine-color-bright)',
	size: 'md',
} as const satisfies IconElementProps;

export function ToggleColorSchemeButton() {
	const { t } = useTranslation('common');
	const { setColorScheme } = useMantineColorScheme();
	const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

	return computedColorScheme === 'light' ? (
		<DarkModeIcon
			tooltip={t('shell.theme.dark')}
			{...commonIconProps}
			onClick={() => setColorScheme('dark')}
		/>
	) : (
		<LightModeIcon
			tooltip={t('shell.theme.light')}
			{...commonIconProps}
			onClick={() => setColorScheme('light')}
		/>
	);
}
