import { Badge, Divider, Group, Loader, LoadingOverlay, Paper, Stack, Text } from '@mantine/core';
import type { BadgeVariant, DefaultMantineColor } from '@mantine/core';

import { useTranslation } from '@apple/lib/i18next';

import { ErrorTable } from './ErrorTable';
import type { ExcelError, ExcelResult } from '../models/excel';
import type { ErrorTableProps } from './ErrorTable';

export type UploadResultsProps<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
> = ErrorTableProps<TItem, TKey, TError> & {
	processing: boolean;
	results: ExcelResult<TItem, TKey, TError>;
};

export function UploadResults<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
>({
	processing,
	results,
	keyProperty,
	keyLabel,
	errorsProperty,
}: UploadResultsProps<TItem, TKey, TError>) {
	const { t } = useTranslation('manage');
	return (
		<Stack align='center'>
			<LoadingOverlay
				visible={processing}
				loaderProps={{
					children: (
						<Stack align='center'>
							<Text>{t('excelUpload.progress.saving')}</Text>
							<Loader />
						</Stack>
					),
				}}
			/>
			<Text>{t('excelUpload.results.message')}</Text>
			<Paper withBorder shadow='sm' radius='md'>
				<Group px='md' align='center' justify='space-evenly' wrap='nowrap'>
					<UploadResult
						label={t('excelUpload.results.labels.updates')}
						count={results.updates.length}
						color={undefined}
						variant='light'
					/>
					<Divider orientation='vertical' />
					<UploadResult
						label={t('excelUpload.results.labels.inserts')}
						count={results.inserts.length}
						color='green'
						variant='light'
					/>
					<Divider orientation='vertical' />
					<UploadResult
						label={t('excelUpload.results.labels.deletes')}
						count={results.deletes.length}
						color='red.7'
						variant='light'
					/>
					<Divider orientation='vertical' />
					<UploadResult
						label={t('excelUpload.results.labels.errors')}
						count={results.errors.length}
						color='red.7'
					/>
				</Group>
			</Paper>
			{results.errors.length > 0 && (
				<ErrorTable
					keyLabel={keyLabel}
					keyProperty={keyProperty}
					errorsProperty={errorsProperty}
					errors={results.errors}
				/>
			)}
		</Stack>
	);
}

function UploadResult({
	label,
	count,
	color,
	variant,
}: {
	label: string;
	count: number;
	color?: DefaultMantineColor | undefined;
	variant?: BadgeVariant | undefined;
}) {
	return (
		<Group p='xs'>
			<Text inline>{label}</Text>
			<Badge
				data-testid={`badge-${label}`}
				size='lg'
				circle
				color={count > 0 ? color : 'gray.7'}
				variant={count > 0 ? variant : 'light'}
			>
				{count}
			</Badge>
		</Group>
	);
}
