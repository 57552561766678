import { z } from 'zod';

import { programIdSchema } from '@apple/features/program/models';
import { countryCodeSchema, emptyOrNullableLocalDateTimeSchema } from '@apple/utils/globalization';
import type { ProgramId } from '@apple/features/program/models';
import type { CountryCode } from '@apple/utils/globalization';

export interface ProgramListing extends Program {
	code: string;
	customerCount: number;
	status: string;
}

export interface ProgramFilters {
	code?: string;
	name?: string;
	customerCount?: number;
	status?: string;
}

export interface ProgramRequest extends Omit<Program, 'id'> {
	id: ProgramId | null;
	arvatoCustomerGroupCode: string;
	countryCode: CountryCode | null;
	description: string | null;
	isDeleted: boolean;
	logoImageUrl: string | null;
}

export interface Program {
	id: ProgramId;
	name: string;
	activeDate: '' | Date | null;
	inactiveDate: '' | Date | null;
}

export const programSchema = z.object({
	id: programIdSchema.nullable(),
	name: z.string().min(1),
	activeDate: emptyOrNullableLocalDateTimeSchema,
	inactiveDate: emptyOrNullableLocalDateTimeSchema,
}) as z.ZodType<Program>;

export const programRequestSchema = programSchema.and(
	z.object({
		id: programIdSchema.nullable(),
		arvatoCustomerGroupCode: z.string().min(1),
		countryCode: countryCodeSchema.nullable(),
		description: z.string().nullable(),
		isDeleted: z.boolean(),
		logoImageUrl: z.string().nullable(),
	}),
) satisfies z.ZodType<ProgramRequest>;

export const programListingSchema = programSchema.and(
	z.object({
		code: z.string(),
		customerCount: z.number(),
		status: z.string(),
	}),
) satisfies z.ZodType<ProgramListing>;
