import { z } from 'zod';

import { countryCodeSchema, currencyCodeSchema } from '@apple/utils/globalization';

export type Plant = z.infer<typeof plantSchema>;

export const plantSchema = z.object({
	warehouseLocationId: z.string().transform(Number),
	name: z.string(),
	description: z.string(),
	warehouseCode: z.string(),
	abbreviation: z.string(),
	currencyCode: currencyCodeSchema,
	countryCodes: z.array(countryCodeSchema),
});
