import { z } from 'zod';
import type { AccessorFnColumnDef, ColumnFilter, RowData } from '@tanstack/react-table';

import { commonFiltersSchema } from '@apple/utils/filters';
import { paginationStateSchema } from '@apple/utils/pagination';

import { log } from './logger';
import type {
	ColumnFilterDef,
	DataTableQueryOptions,
	FieldMap,
	FilterData,
	FilterVariant,
} from '../types';

export function createDataTableQueryOptionsSchema<
	TFilters extends FilterData<TFilters>,
	TFiltersSchema extends z.ZodType<TFilters> = z.ZodType<TFilters>,
>(filtersSchema: TFiltersSchema) {
	return z.object({
		pagination: paginationStateSchema,
		filters: filtersSchema,
		commonFiltersSchema: commonFiltersSchema.optional(),
	}) as z.ZodType<DataTableQueryOptions<TFilters>>;
}

export function createFilterOnlyColumn<
	TRowData extends RowData,
	TFilters extends FilterData<TFilters>,
	FilterField extends keyof TFilters & string = keyof TFilters & string,
>(options: FilterOnlyColumnOptions<TFilters, FilterField>) {
	log.debug('createFilterOnlyColumn', options);
	return {
		id: options.field,
		header: options.label,
		accessorFn: () => '' as TFilters[FilterField],
		enableColumnFilter: !options.hidden,
		filter: {
			group: options.group,
			variant: options.variant,
			filterFromString: options.filterFromString,
			getFilterDisplayValue: options.getFilterDisplayValue,
		},
		meta: {
			hideColumn: true,
		},
	} satisfies AccessorFnColumnDef<TRowData, TFilters[FilterField]>;
}

interface FilterOnlyColumnOptions<
	TFilters extends FilterData<TFilters>,
	FilterField extends keyof TFilters = keyof TFilters,
> {
	field: FilterField;
	group: string;
	label: string;
	variant: FilterVariant;
	// options: TFilters[FilterField][];
	hidden?: boolean;

	// HACK: need to find a better way to handle this...
	filterFromString?: ColumnFilterDef<RowData, TFilters[FilterField]>['filterFromString'];
	// HACK: need to find a better way to handle this...
	getFilterDisplayValue?: ColumnFilterDef<
		RowData,
		TFilters[FilterField]
	>['getFilterDisplayValue'];
}

export function getFilterDataFromColumnFilters<
	TRow extends RowData,
	TFilters extends FilterData<TFilters>,
>(columnFilters: ColumnFilter[], mappings: FieldMap<TRow, TFilters>[]): TFilters {
	return columnFilters.reduce((acc, rowFilter) => {
		const mappedId =
			mappings.find(x => x.row == rowFilter.id)?.filter ?? (rowFilter.id as keyof TFilters);
		acc[mappedId] = rowFilter.value as TFilters[keyof TFilters];
		return acc;
	}, {} as TFilters);
}

export function getColumnFiltersFromFilterData<
	TRow extends RowData,
	TFilters extends FilterData<TFilters>,
>(filters: TFilters, mappings: FieldMap<TRow, TFilters>[]): ColumnFilter[] {
	return Object.entries(filters).map(([key, value]) => {
		const mappedId = mappings.find(x => x.filter == key)?.row ?? key;
		return {
			id: mappedId as string,
			value,
		} satisfies ColumnFilter;
	});
}
