import { Container, rem, Title, useMantineTheme } from '@mantine/core';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
	title: string;
}>;

export function TitleLayout({ title, children }: Props) {
	const theme = useMantineTheme();

	return (
		<Container size='xl' pt='xs' pb='xl' mt={rem(theme.other.headerHeight)}>
			<Title order={1}>{title}</Title>
			{children}
		</Container>
	);
}
