import { SidebarToggleButton } from '@apple/ui/layouts/components/sidebar-toggle-button';
import {
	useRightSidebarCollapsed,
	useRightSidebarToggleButtonProps,
} from '@apple/ui/shell/contexts/right-sidebar';
import type { SidebarToggleButtonProps } from '@apple/ui/layouts/components/sidebar-toggle-button';

type Props = Omit<SidebarToggleButtonProps, 'collapsed' | 'onClick'> & {
	hideWhen?: 'collapsed' | 'expanded';
};

export function ToggleRightSidebarButton({ hideWhen, ...props }: Props) {
	const { hasComponent, isCollapsed, toggle } = useRightSidebarCollapsed();
	const toggleButtonProps = useRightSidebarToggleButtonProps();
	const shouldHide =
		!hasComponent || hideWhen ? (hideWhen === 'collapsed' ? isCollapsed : !isCollapsed) : false;
	return shouldHide ? null : (
		<SidebarToggleButton
			{...toggleButtonProps}
			{...props}
			collapsed={isCollapsed}
			onClick={toggle}
		/>
	);
}
