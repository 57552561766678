import { memo } from 'react';
import { Group, Input, Table } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import classes from './list.module.css';

export const ListItem = memo(_ListItem);
ListItem.displayName = 'ListItem';

function _ListItem({
	label,
	hidden = false,
	htmlFor,
	children,
}: PropsWithChildren<{ label: string; hidden?: boolean; htmlFor?: string }>) {
	return (
		<Table.Tr className={classes.item} hidden={hidden}>
			<Table.Td>
				<Group wrap='nowrap' align='center' justify='space-between' w='100%'>
					<Input.Label styles={{ label: { textWrap: 'nowrap' } }} htmlFor={htmlFor}>
						{label}
					</Input.Label>
					{children}
				</Group>
			</Table.Td>
		</Table.Tr>
	);
}
