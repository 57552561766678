import { rem } from '@mantine/core';
import { modals } from '@mantine/modals';
import type { TFunction } from 'i18next';

import { AnnouncementItem } from './AnnouncementItem';
import type { Announcement } from '../models/announcement';

type Props = {
	t: TFunction;
	announcement: Announcement;
	onClose?: () => void;
	onAcknowledge?: () => void;
	onRemindMeLater?: () => void;
};

export function openAnnouncementModal({
	t,
	announcement,
	onClose,
	onAcknowledge,
	onRemindMeLater,
}: Props) {
	modals.openConfirmModal({
		modalId: 'active-announcement-modal',
		title: t('announcement:active.title'),
		// data-testid: 'active-announcement-modal',
		// onClose: handleClose,
		radius: 'xl',
		withCloseButton: false,
		closeOnClickOutside: false,
		size: 'lg',
		centered: true,
		xOffset: 0,
		yOffset: 0,
		padding: 'xl',
		styles: {
			title: {
				width: '100%',
				textAlign: 'center',
			},
		},
		// TODO: Add onConfirm and onCancel callbacks and update the button labels based on the current announcement state
		confirmProps: announcement.acknowledged
			? { display: 'none' }
			: {
					children: t('announcement:active.buttons.acknowledge'),
				},
		onConfirm: onAcknowledge,
		onClose: onClose,
		onCancel: announcement.acknowledged ? onClose : onRemindMeLater,
		cancelProps: {
			children: announcement.acknowledged
				? t('common:buttons.close')
				: t('announcement:active.buttons.remindMeLater'),
		},
		children: <AnnouncementItem announcement={announcement} mih={rem(500)} />,
	});
}
