import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

export interface LocationApprovalRequest {
	userId: number;
	username: string;
	firstName: string;
	lastName: string;
	role: string;
	requests: number;
	date?: Date | string | null;
}

export interface LocationApprovalFilters {
	username?: string;
	role?: string;
	date?: Date | string | null;
}

export interface LocationUser {
	id: number;
	email: string;
	username: string;
	firstName: string;
	lastName: string;
}

export const locationApprovalRequestSchema = z.object({
	userId: z.number(),
	username: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	role: z.string(),
	requests: z.number(),
	date: dateTimeSchema,
}) satisfies z.ZodType<LocationApprovalRequest>;

export const locationUserSchema = z.object({
	id: z.number(),
	email: z.string().email(),
	username: z.string().min(1),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
}) satisfies z.ZodType<LocationUser>;
