import { useMutation, useQueryClient } from '@tanstack/react-query';

import { announcementQueryKeys } from '@apple/features/announcements/queries/announcement';

import { acknowledgeContent } from '../api/announcement';

export function useAcknowledgeAnnouncement() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['announcement', 'acknowledge'],
		mutationFn: acknowledgeContent,
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: announcementQueryKeys.all() });
		},
	});
}
