import { Menu, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';

import { DarkModeIcon, LightModeIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';

export function ToggleColorSchemeMenuItem() {
	const { toggleColorScheme } = useMantineColorScheme();
	const currentColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });
	const { t } = useTranslation('common');
	// <IconSwitchHorizontal
	// 	style={{ width: rem(16), height: rem(16) }}
	// 	stroke={1.5}
	// />
	return (
		<Menu.Item
			onClick={toggleColorScheme}
			leftSection={currentColorScheme === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
		>
			{currentColorScheme === 'light' ? t('shell.theme.dark') : t('shell.theme.light')}
		</Menu.Item>
	);
}
