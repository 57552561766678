import { RichTextEditor } from '@mantine/tiptap';
import type { RichTextEditorProps } from '@mantine/tiptap';
import type { Editor } from '@tiptap/react';

export type TextEditorProps = Omit<RichTextEditorProps, 'children'> & {
	editor: Editor | null;
	displayToolBar?: boolean;
};

export function TextEditor({ editor, displayToolBar = true, ...props }: TextEditorProps) {
	return (
		editor && (
			<RichTextEditor
				editor={editor}
				// styles={
				// 	displayToolBar
				// 		? undefined
				// 		: {
				// 				content: { margin: '-16px' },
				// 			}
				// }
				{...props}
			>
				{displayToolBar && (
					<RichTextEditor.Toolbar sticky stickyOffset={60}>
						<RichTextEditor.ControlsGroup>
							<RichTextEditor.Bold />
							<RichTextEditor.Italic />
							<RichTextEditor.Underline />
							<RichTextEditor.ClearFormatting />
						</RichTextEditor.ControlsGroup>

						<RichTextEditor.ControlsGroup>
							<RichTextEditor.H1 />
							<RichTextEditor.H2 />
							<RichTextEditor.H3 />
							<RichTextEditor.H4 />
						</RichTextEditor.ControlsGroup>

						<RichTextEditor.ControlsGroup>
							<RichTextEditor.Hr />
							<RichTextEditor.BulletList />
							<RichTextEditor.OrderedList />
						</RichTextEditor.ControlsGroup>

						<RichTextEditor.ControlsGroup>
							<RichTextEditor.Link />
							<RichTextEditor.Unlink />
						</RichTextEditor.ControlsGroup>

						<RichTextEditor.ControlsGroup>
							<RichTextEditor.AlignLeft />
							<RichTextEditor.AlignCenter />
							<RichTextEditor.AlignJustify />
							<RichTextEditor.AlignRight />
						</RichTextEditor.ControlsGroup>

						<RichTextEditor.ControlsGroup>
							<RichTextEditor.Undo />
							<RichTextEditor.Redo />
						</RichTextEditor.ControlsGroup>
					</RichTextEditor.Toolbar>
				)}

				<RichTextEditor.Content data-testid='description-input' />
			</RichTextEditor>
		)
	);
}
