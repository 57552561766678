import { memo, useState } from 'react';
import { Checkbox, Paper, SimpleGrid } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';

import { useTranslation } from '@apple/lib/i18next';
import { countryCodeSchema } from '@apple/utils/globalization';
import type { Plant } from '@apple/features/plants/models';
import type { Program } from '@apple/features/program/models';
import type { CountryCode, CountryWithCultures } from '@apple/utils/globalization';

import { ProgramCountryFilter } from './ProgramCountryFilter';
import type { Product, ProgramAssociation, SubProgramAssociation } from '../../models/management';
import type { CountryFilter } from './ProgramCountryFilter';

export type ProgramCountriesProps = {
	form: UseFormReturnType<Product>;
	program: Program;
	countries: CountryWithCultures[];
	plants: Plant[];
	onChange: (program: Program, countries: CountryCode[]) => void;
};

function getCountriesFromAssociations(
	program: Program,
	programAssociations: ProgramAssociation[],
	programCountryAssociations: SubProgramAssociation[],
): CountryCode[] {
	return programAssociations.some(x => x.customerGroupId === program.id)
		? program.countries
		: (programCountryAssociations
				.filter(x => x.customerGroupId === program.id)
				?.map(y => y.countryCode) ?? []);
}

export const ProgramCountries = memo<ProgramCountriesProps>(
	({ form, program, countries, plants, onChange }: ProgramCountriesProps) => {
		const { customerGroupAssociations, customerGroupCountryAssociations } = form.getValues();
		const [filteredCountries, setFilteredCountries] = useState(program.countries);
		const { t } = useTranslation('product');

		const handleFilterChange = (filter: CountryFilter) => {
			const selectedPlant = plants.find(x => x.name === filter.plant);

			setFilteredCountries(
				program.countries.filter(
					x =>
						(!selectedPlant || selectedPlant.countryCodes.includes(x)) &&
						countries
							.filter(x =>
								x.name.toLowerCase().includes(filter.searchText.toLowerCase()),
							)
							.map(y => y.code)
							.includes(x),
				),
			);
		};

		const handleToggleFilteredCountries = (selected: boolean) => {
			let countries = getCountriesFromAssociations(
				program,
				customerGroupAssociations,
				customerGroupCountryAssociations,
			).filter(country => !filteredCountries.includes(country));

			countries = selected ? countries.concat(filteredCountries) : countries;
			onChange(program, countries);
		};

		return (
			<>
				<ProgramCountryFilter
					program={program}
					plants={plants}
					onFilterChanged={handleFilterChange}
					handleToggleFilteredCountries={handleToggleFilteredCountries}
				/>
				<Paper radius='xs' mt='md' withBorder p='xs'>
					<Checkbox.Group
						value={getCountriesFromAssociations(
							program,
							customerGroupAssociations,
							customerGroupCountryAssociations,
						)}
						onChange={value =>
							onChange(program, countryCodeSchema.array().parse(value))
						}
						label={t('associations.labels.selectCountries')}
					>
						<SimpleGrid mt='sm' cols={{ base: 2, sm: 4, md: 6 }}>
							{program.countries.map((country, j) => {
								const countryLabel = countries.find(x => x.code === country)?.name;
								return (
									countryLabel && (
										<Checkbox
											data-testid={`${program.code}-${country}`}
											display={
												!filteredCountries.includes(country)
													? 'none'
													: undefined
											}
											key={country}
											value={country}
											label={countryLabel}
										/>
									)
								);
							})}
						</SimpleGrid>
					</Checkbox.Group>
				</Paper>
			</>
		);
	},
);
