import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

import { sortedImageSchema } from './image';
import type { SortedImage } from './image';

export type ProductKey = z.infer<typeof productKeySchema>;
export const productKeySchema = z.string();

export interface ProductInfo {
	key: ProductKey;
	name: string;
	price: number;
}

export interface ProductListingRequest {
	favoritesOnly: boolean;
	confidentialOnly?: boolean | null;
	selectedOnly?: boolean | null;
	searchText?: string;
	currentPage?: number | null;
	pageSize?: number | null;
	productCategory?: string;
	programId?: number | null;
	sortByOption: ProductSortOptions;
}

export interface ProductModel {
	itemId: ProductKey;
	title: string;
	sku: string;
	unitOfMeasure: string;
	itemState: string;
	description: string;
	imageUrl?: string | null;
	images: SortedImage[];
	externalUrl?: string | null;
}

export const productModelSchema = z.object({
	itemId: productKeySchema,
	title: z.string(),
	sku: z.string(),
	unitOfMeasure: z.string(),
	itemState: z.string(),
	description: z.string(),
	imageUrl: z.string().optional().nullable(),
	images: z.array(sortedImageSchema),
	externalUrl: z.string().optional().nullable(),
}) satisfies z.ZodType<ProductModel>;

export interface ProductListingModel extends ProductModel {
	requiresApproval: boolean;
	estimatedInStockDate?: string | null;
	beginDate?: string | Date | null;
	endDate?: string | Date | null;
	deleted: boolean;
	showInventory: boolean;
	administratorOnly: boolean;
	showInventoryStatus: boolean;
	updatedDate?: string | Date | null;
	quantityLimit?: number | null;
	isBackorderable: boolean;
	unitPrice: string;
	numericUnitPrice: number;
	displayString: string;
}

export const productListingModelSchema = productModelSchema.extend({
	requiresApproval: z.boolean(),
	estimatedInStockDate: z.string().nullable(),
	beginDate: dateTimeSchema.nullable(),
	endDate: dateTimeSchema.nullable(),
	deleted: z.boolean(),
	showInventory: z.boolean(),
	administratorOnly: z.boolean(),
	showInventoryStatus: z.boolean(),
	updatedDate: dateTimeSchema.nullable(),
	quantityLimit: z.number().nullable(),
	isBackorderable: z.boolean(),
	unitPrice: z.string(),
	numericUnitPrice: z.number(),
	displayString: z.string(),
}) satisfies z.ZodType<ProductListingModel>;

export interface ProductAutocompleteRequest {
	searchText: string;
	partNumbers: string[];
	customerCode: string;
}

export const productAutocompleteRequestSchema = z.object({
	searchText: z.string(),
	partNumbers: z.array(z.string()),
	customerCode: z.string(),
}) satisfies z.ZodType<ProductAutocompleteRequest>;

export type ProductSortOptions =
	| 'Ascending'
	| 'Descending'
	| 'PriceAscending'
	| 'PriceDescending'
	| 'NewToOld'
	| 'MostPopular';
