export const materials = {
	headerViewLight: {
		background: 'rgba(255, 255, 255, 0.80)',
		backdropFilter: 'blur(15px)',
	},
	headerViewDark: {
		background:
			'linear-gradient(0deg, rgba(40, 82, 173, 0.08) 0%, rgba(40, 82, 173, 0.08) 100%), rgba(30, 30, 30, 0.80))',
		backdropFilter: 'blur(15px)',
	},
	menuLight: {
		background: 'rgba(246, 246, 246, 0.72)',
		backdropFilter: 'blur(15px)',
	},
	menuDark: {
		background: 'rgba(40, 40, 40, 0.58)',
		backdropFilter: 'blur(15px)',
	},
	popoverLight: {
		background: 'rgba(246, 246, 246, 0.60)',
		backdropFilter: 'blur(15px)',
	},
	popoverDark: {
		background: 'rgba(0, 0, 0, 0.28)',
		backdropFilter: 'blur(15px)',
	},
	fullscreenDark: {
		background: 'rgba(40, 40, 40, 0.50)',
		backdropFilter: 'blur(15px)',
	},
	selectionFocusedLight: {
		background:
			'linear-gradient(0deg, rgba(10, 130, 255, 0.75) 0%, rgba(10, 130, 255, 0.75) 100%), #0A82FF',
		backgroundBlendMode: 'normal, soft-light',
		backdropFilter: 'blur(15px)',
	},
	selectionFocusedDark: {
		background:
			'linear-gradient(0deg, rgba(10, 130, 255, 0.75) 0%, rgba(10, 130, 255, 0.75) 100%), #0A82FF',
		backgroundBlendMode: 'normal, soft-light',
		backdropFilter: 'blur(40.774227142333984px)',
	},
	selectionUnfocusedLight: {
		background:
			'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), rgba(246, 246, 246, 0.84)',
		backgroundBlendMode: 'multiply, normal',
		backdropFilter: 'blur(15px)',
	},
	selectionUnfocusedDark: {
		background:
			'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(40, 40, 40, 0.65)',
		backgroundBlendMode: 'screen, normal',
		backdropFilter: 'blur(15px)',
	},
	sidebarLight: {
		background: 'rgba(234, 234, 234, 0.84)',
		backdropFilter: 'blur(15px)',
	},
	sidebarDark: {
		background: 'rgba(0, 0, 0, 0.45)',
		backdropFilter: 'blur(15px)',
	},
	hudDark: {
		background: 'rgba(40, 40, 40, 0.50)',
		backdropFilter: 'blur(15px)',
	},
	titleBarLight: {
		background: 'rgba(234, 234, 234, 0.80)',
		backdropFilter: 'blur(15px)',
	},
	titleBarDark: {
		background: 'rgba(60, 60, 60, 0.80)',
		backdropFilter: 'blur(15px)',
	},
	tooltipLight: {
		background: 'rgba(246, 246, 246, 0.60)',
		backdropFilter: 'blur(15px)',
	},
	tooltipDark: {
		background: 'rgba(0, 0, 0, 0.35)',
		backdropFilter: 'blur(17.5px)',
	},
	underWindowBackgroundLight: {
		background: 'rgba(246, 246, 246, 0.84)',
		backdropFilter: 'blur(25px)',
	},
	underWindowBackgroundDark: {
		background: 'rgba(0, 0, 0, 0.45)',
		backdropFilter: 'blur(25px)',
	},
	chromeLight: {
		background: 'rgba(255, 255, 255, 0.75)',
		backgroundBlendMode: 'hard-light',
		backdropFilter: 'blur(25px)',
	},
	chromeDark: {
		background: 'linear-gradient(0deg, #7C7C7C 0%, #7C7C7C 100%), rgba(28, 28, 28, 0.90)',
		backgroundBlendMode: 'overlay, normal',
		backdropFilter: 'blur(25px)',
	},
	thickLight: {
		background: 'linear-gradient(0deg, #5C5C5C 0%, #5C5C5C 100%), rgba(153, 153, 153, 0.97);',
		backgroundBlendMode: 'color-dodge, normal',
		backdropFilter: 'blur(25px)',
	},
	thickDark: {
		background: 'linear-gradient(0deg, #7C7C7C 0%, #7C7C7C 100%), rgba(37, 37, 37, 0.90)',
		backgroundBlendMode: 'overlay, normal',
		backdropFilter: 'blur(25px)',
	},
	regularLight: {
		background: 'linear-gradient(0deg, #383838 0%, #383838 100%), rgba(179, 179, 179, 0.82)',
		backgroundBlendMode: 'color-dodge, normal',
		backdropFilter: 'blur(25px)',
	},
	regularDark: {
		background: 'linear-gradient(0deg, #8C8C8C 0%, #8C8C8C 100%), rgba(37, 37, 37, 0.82)',
		backgroundBlendMode: 'overlay, normal',
		backdropFilter: 'blur(25px)',
	},
	thinLight: {
		background: 'linear-gradient(0deg, #333 0%, #333 100%), rgba(166, 166, 166, 0.70)',
		backgroundBlendMode: 'color-dodge, normal',
		backdropFilter: 'blur(25px)',
	},
	thinDark: {
		background: 'linear-gradient(0deg, #9C9C9C 0%, #9C9C9C 100%), rgba(37, 37, 37, 0.70)',
		backgroundBlendMode: 'overlay, normal',
		backdropFilter: 'blur(25px)',
	},
	ultraThinLight: {
		background: 'linear-gradient(0deg, #0D0D0D 0%, #0D0D0D 100%), rgba(191, 191, 191, 0.44)',
		backgroundBlendMode: 'color-dodge, normal',
		backdropFilter: 'blur(25px)',
	},
	ultraThinDark: {
		background: 'linear-gradient(0deg, #9C9C9C 0%, #9C9C9C 100%), rgba(37, 37, 37, 0.55)',
		backgroundBlendMode: 'overlay, normal',
		backdropFilter: 'blur(25px)',
	},
} as const;
