import axios from 'axios';

import type { ProgramId } from '@apple/features/program/models';

import { programRequestSchema } from './management.models';
import type { ProgramRequest } from './management.models';

export async function getProgram(programId: ProgramId, signal?: AbortSignal) {
	const data = (await axios.get<unknown>(`/api/management/programs/${programId}`, { signal }))
		.data;
	return programRequestSchema.parse(data);
}

export async function addProgram(request: ProgramRequest) {
	await axios.post('/api/management/programs/', request);
}

export async function updateProgram(request: ProgramRequest) {
	await axios.put('/api/management/programs/', request);
}

export async function deleteProgram(programId: ProgramId) {
	await axios.delete(`/api/management/programs/${programId}`);
}
