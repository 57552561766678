import { useCallback, useMemo, useState } from 'react';
import { FileInput } from '@mantine/core';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

export type FileImportInputProps = {
	onValidFileSelection: (payload: File) => void;
	allowedTypes?: string[];
	maxFileSize?: number;
	disabled?: boolean;
};

export function FileImportInput({
	onValidFileSelection,
	allowedTypes = ['*'],
	maxFileSize = 2048 * 1024,
	disabled = false,
}: FileImportInputProps) {
	const { t } = useTranslation('common');
	const allowedTypesString = useMemo(() => allowedTypes.join(','), [allowedTypes]);
	const [error, setError] = useState<string | undefined>();

	const onFileChosen = useCallback(
		(file: File | null) => {
			setError(undefined);
			if (!file) return;

			const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
			const isValidType = allowedTypes.some(
				type => type.toLowerCase() === fileExtension || type === '*',
			);

			if (!isValidType) {
				setError(t('fileImport.errors.invalidType'));
				return;
			}

			if (file.size > maxFileSize) {
				setError(t('fileImport.errors.fileTooLarge'));
				return;
			}

			onValidFileSelection(file);
		},
		[allowedTypes, maxFileSize, onValidFileSelection, t],
	);

	return (
		<FileInput
			disabled={disabled}
			placeholder={t('fileImport.placeholder')}
			label={t('fileImport.label')}
			rightSection={<icons.File />}
			accept={allowedTypesString}
			onChange={onFileChosen}
			error={error}
			data-testid='file-input'
		/>
	);
}
