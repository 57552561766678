import axios from 'axios';

import type { UserId } from '@apple/features/auth';

import type {
	Location,
	LocationAutocompleteRequest,
	LocationId,
	LocationListingRequest,
	LocationListingResponse,
} from '../models/location';

/** Service wrapper for:
 * - AppleBrandedPrograms.Web.Features.Locations.LocationsController
 * - AppleBrandedPrograms.Web.Features.Locations.LocationsListingController
 */

export async function getUsersAssociatedToLocation(locationId: LocationId, signal?: AbortSignal) {
	return (
		await axios.get<UserId[]>(`/api/locations/all/${locationId}/associated-users`, {
			signal,
		})
	).data;
}

export async function getLocationById(locationId: LocationId, signal?: AbortSignal) {
	return (await axios.get<Location>(`/api/locations/all/${locationId}`, { signal })).data;
}

export async function getLocationsByIds(
	locationIds: LocationId[],
	signal?: AbortSignal,
): Promise<Location[]> {
	return (
		await axios.post<Location[]>('/api/locations/all', locationIds, {
			signal,
		})
	).data;
}

export function getLocationsUri(request: LocationListingRequest, signal?: AbortSignal): string {
	return axios.getUri({
		url: '/api/locations/listing',
		params: request,
		signal,
	});
}

export async function getLocations(request: LocationListingRequest, signal?: AbortSignal) {
	return (
		await axios.get<LocationListingResponse>('/api/locations/listing', {
			params: request,
			signal,
		})
	).data;
}

export async function autocomplete(
	request: LocationAutocompleteRequest,
	signal?: AbortSignal,
): Promise<Location[]> {
	return (await axios.post<Location[]>('/api/locations/autocomplete/new', request, { signal }))
		.data;
}
