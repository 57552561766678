import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { getLogger } from 'loglevel';
import type { ZodObject, ZodRawShape } from 'zod';

import { useTranslation } from '@apple/lib/i18next';
import type { CountryCode } from '@apple/utils/globalization';

import {
	validationAddressCharacterWhitelistQueryOptions,
	validationAddressRulesQueryOptions,
	validationCharacterWhitelistQueryOptions,
	validationFormRulesQueryOptions,
} from '../queries';
import { extendSchemaWithServerRules } from '../zod';
import type { ValidationType } from '../models';

const log = getLogger('validation');

export function useValidationSchema<T extends ZodObject<ZodRawShape>>({
	validationType,
	countryCode,
	existingSchema,
	useDefaultWhitelist = false,
}: {
	validationType: ValidationType;
	countryCode: CountryCode | undefined;
	existingSchema: T;
	useDefaultWhitelist?: boolean;
}) {
	const { t } = useTranslation('zod');
	const validation = useQueries({
		queries: [
			validationType === 'Address' && countryCode !== undefined
				? validationAddressRulesQueryOptions(countryCode)
				: validationFormRulesQueryOptions({ validationType, countryCode }),
			validationType === 'Address' && countryCode !== undefined && !useDefaultWhitelist
				? validationAddressCharacterWhitelistQueryOptions(countryCode)
				: validationCharacterWhitelistQueryOptions(),
		],
		combine: ([fieldRulesQuery, characterWhitelistQuery]) => {
			return {
				fieldRulesQuery,
				characterWhitelistQuery,
				fieldRules: fieldRulesQuery.data ?? [],
				characterWhitelist: characterWhitelistQuery.data ?? [],
				loading: fieldRulesQuery.isLoading || characterWhitelistQuery.isLoading,
				errors: [fieldRulesQuery.error, characterWhitelistQuery.error].filter(x => !!x),
				refetch: () =>
					Promise.all([fieldRulesQuery.refetch(), characterWhitelistQuery.refetch()]),
			};
		},
	});

	if (validation.errors.length > 0) {
		log.error('Error fetching validation rules:', validation.errors);
	}

	const schema = useMemo(() => {
		if (validation.fieldRules === undefined || validation.fieldRules?.length === 0) {
			return existingSchema;
		}

		log.debug('Extending schema with server rules:', {
			validationType,
			countryCode,
			fieldRules: validation.fieldRules,
			characterWhitelist: validation.characterWhitelist,
		});

		return extendSchemaWithServerRules({
			existingSchema,
			rules: validation.fieldRules,
			whitelist: validation.characterWhitelist,
			t,
			ignoreLocalization: validationType === 'Address',
		});
	}, [
		countryCode,
		existingSchema,
		t,
		validation.characterWhitelist,
		validation.fieldRules,
		validationType,
	]);

	return {
		schema,
		...validation,
	};
}
