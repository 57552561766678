import { Box, Center, Group, Image, Paper, Stack, Title, Tooltip } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import {
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { CellContext } from '@tanstack/react-table';

import { icons, images } from '@apple/assets';
import { OrderCommentStatus } from '@apple/features/order/models/search';
import { getApprovalOrdersOptions } from '@apple/features/order/queries/approval';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable } from '@apple/ui/data-table';
import { Link } from '@apple/ui/link';
import { FormattedDate } from '@apple/utils/globalization';
import { DEFAULT_PAGE_INDEX } from '@apple/utils/pagination';
import type { OrderApprovalListing } from '@apple/features/order/models/approval';

const PAGE_SIZE = 20;

type Props = {
	renderOrderLink: (ctx: CellContext<OrderApprovalListing, unknown>) => React.ReactNode;
};

export function OrdersPendingApprovalTable({ renderOrderLink }: Props) {
	const { t } = useTranslation('order-approval');
	const ordersQuery = useQuery(getApprovalOrdersOptions);
	const table = useReactTable({
		data: ordersQuery.data,
		getCoreRowModel: getCoreRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: true,
		columnResizeMode: 'onChange',
		enableFilters: false,
		enableColumnFilters: false,
		manualFiltering: false,
		manualSorting: false,
		initialState: {
			sorting: [
				{
					id: 'orderDate',
					desc: true,
				},
			],
			pagination: { pageSize: PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX },
		},
		columns: [
			{
				accessorKey: 'purchaseOrderNumber',
				header: t('headers.orderNumber'),
				filter: {
					group: t('order:filters.groups.labels.orderInfo'),
					variant: 'text',
				},
				size: 100,
				cell: renderOrderLink,
			},
			{
				accessorKey: 'orderDate',
				header: t('headers.orderDate'),
				enableSorting: true,
				sortingFn: 'datetime',
				filter: {
					group: t('order:filters.groups.labels.orderInfo'),
					variant: 'date-range',
				},
				size: 100,
				cell: ({ row }) => <FormattedDate value={row.original.orderDate} humanize />,
			},
			{
				accessorKey: 'appleId',
				header: t('headers.appleId'),
				filter: {
					group: t('order:filters.groups.labels.location'),
					variant: 'text',
				},
				size: 100,
			},
			{
				id: 'orderStatusIcon',
				size: 50,
				cell: ({ row }) => {
					if (!row.original.hasComments) {
						return <Box style={{ width: '24px', height: '24px' }} />;
					}
					return (
						<Tooltip.Group>
							<Tooltip
								transitionProps={{ transition: 'fade-up', duration: 200 }}
								label={
									row.original.orderCommentStatus == OrderCommentStatus.Sent
										? t('tooltip.awaitingResponse')
										: t('tooltip.responseReceived')
								}
							>
								{row.original.orderCommentStatus == OrderCommentStatus.Sent ? (
									<Center>
										<icons.AdminOpenQuestion
											c='blue'
											data-testid={`has-comment-${row.original.purchaseOrderNumber}`}
										/>
									</Center>
								) : (
									<Center>
										<icons.UserRepliedQuestion
											c='bright'
											data-testid={`has-comment-${row.original.purchaseOrderNumber}`}
										/>
									</Center>
								)}
							</Tooltip>
						</Tooltip.Group>
					);
				},
			},
		],
	});

	function renderEmpty() {
		return (
			<Stack align='center'>
				<Title order={3}>{t('noPendingOrdersCongratulations')}</Title>
				<Image radius='lg' src={images.NoPendingApproval} w={150} fit='contain' />
				<Title order={3}>{t('noPendingOrdersMessage')}</Title>
			</Stack>
		);
	}

	return (
		<Stack>
			<Group justify='space-between'>
				<Title px='md' order={4}>
					{t('pendingApproval')}
				</Title>
				<Link to='/approvals/orders' title='Search all orders' px='md'>
					<icons.Search c='bright' />
				</Link>
			</Group>
			<Paper mx='md' p='sm' withBorder>
				<DataTable
					hidePageSize
					hidePagination={!table.getCanNextPage() && !table.getCanPreviousPage()}
					table={table}
					variant='apple-table'
					minWidth='500px'
					loading={ordersQuery.isFetching}
					renderEmpty={renderEmpty}
				/>
			</Paper>
		</Stack>
	);
}
