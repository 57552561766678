import { useCallback, useEffect, useMemo } from 'react';
import { useIsFirstRender, useSessionStorage } from '@mantine/hooks';
import { functionalUpdate } from '@tanstack/react-router';
import { isEqual } from 'lodash-es';
import type {
	ColumnFilter,
	ColumnFiltersState,
	PaginationState,
	SortingState,
	Updater,
} from '@tanstack/table-core';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@apple/utils/pagination';

import { getColumnFiltersFromFilterData, getFilterDataFromColumnFilters } from '../utils/filters';
import type { DataTableState, FieldMap } from '../types';

type Props<TRow, TFilters> = {
	tableName: string;
	defaultFilters?: TFilters;
	defaultSorting?: SortingState;
	defaultPageSize?: number;
	fieldMap: FieldMap<TRow, TFilters>[];
};

export function useTableState<TRow, TFilters>({
	tableName,
	defaultFilters,
	defaultPageSize = DEFAULT_PAGE_SIZE,
	defaultSorting = [],
	fieldMap,
}: Props<TRow, TFilters>) {
	const isFirstRender = useIsFirstRender();

	const defaultColumnFilters = useMemo(() => {
		return !defaultFilters || !isFirstRender
			? []
			: getColumnFiltersFromFilterData<TRow, TFilters>(defaultFilters, fieldMap);
	}, [defaultFilters, fieldMap, isFirstRender]);

	const initialState = useMemo(
		() => getDefaults(defaultColumnFilters, defaultSorting, defaultPageSize),
		[defaultColumnFilters, defaultSorting, defaultPageSize],
	);

	const [state, setState] = useSessionStorage<DataTableState>({
		key: 'table-state-' + tableName,
		defaultValue: initialState,
		getInitialValueInEffect: false,
	});

	useEffect(() => {
		if (isFirstRender && !isEqual(state, initialState) && defaultFilters) {
			const mergedState = {
				...state,
				columnFilters: [
					...state.columnFilters.filter(
						y =>
							!initialState.columnFilters.some(
								x => x.id === y.id && x.value !== undefined,
							),
					),
					...initialState.columnFilters.filter(x => x.value !== undefined),
				],
			};

			setState(mergedState);
		}
	}, [initialState, setState, state, isFirstRender, defaultFilters]);

	const currentFilterData = useMemo(
		() => getFilterDataFromColumnFilters<TRow, TFilters>(state.columnFilters, fieldMap),
		[state.columnFilters, fieldMap],
	);

	const onPaginationChange = useCallback(
		(valueOrUpdater: Updater<PaginationState>) =>
			setState(prev => ({
				...prev,
				pagination: functionalUpdate(valueOrUpdater, prev.pagination),
			})),
		[setState],
	);

	const onColumnFiltersChange = useCallback(
		(valueOrUpdater: Updater<ColumnFiltersState>) =>
			setState(prev => ({
				...prev,
				columnFilters: functionalUpdate(valueOrUpdater, prev.columnFilters),
				pagination: {
					...prev.pagination,
					// Reset pagination when filters change
					pageIndex: DEFAULT_PAGE_INDEX,
				},
			})),
		[setState],
	);

	const onSortingChange = useCallback(
		(valueOrUpdater: Updater<SortingState>) =>
			setState(prev => ({
				...prev,
				sorting: functionalUpdate(valueOrUpdater, prev.sorting),
			})),
		[setState],
	);

	return {
		initialState,
		state,
		currentFilterData,
		setState,
		onPaginationChange,
		onColumnFiltersChange,
		onSortingChange,
	};
}

function getDefaults(
	defaultColumnFilters: ColumnFilter[],
	defaultSorting: SortingState,
	pageSize: number,
): DataTableState {
	return {
		pagination: {
			pageIndex: DEFAULT_PAGE_INDEX,
			pageSize: pageSize,
		},
		globalFilter: '',
		columnFilters: defaultColumnFilters,
		sorting: defaultSorting,
	};
}
