import type { QueryClient } from '@tanstack/react-query';
import type { TFunction } from 'i18next';

import { cartQueryOptions } from '@apple/features/cart/queries';
import type { StepName } from '@apple/channel/routes/_authed/-steps/types';
import type { OrderShipToAddress } from '@apple/features/address/models';
import type { CartId } from '@apple/features/cart/models/models';
import type { LocationId } from '@apple/features/location/models/location';
import type { LocationOrder } from '@apple/features/order/models/item';
import type { ProductKey } from '@apple/features/product/models/product';

type Options = {
	cartId: CartId;
	queryClient: QueryClient;
};

export async function getFirstInvalidStep({ cartId, queryClient }: Options): Promise<StepName> {
	const selectedLocations = await queryClient.fetchQuery(
		cartQueryOptions.selectedLocations(cartId),
	);
	const selectedProducts =
		selectedLocations.length === 0
			? []
			: await queryClient.fetchQuery(cartQueryOptions.selectedProducts(cartId));

	const selectedQuantities =
		selectedProducts.length === 0
			? []
			: (
					await queryClient.fetchQuery(
						cartQueryOptions.selectedQuantities({
							cartId,
							excludeZeroQuantity: false,
							enabled: selectedProducts.length > 0,
						}),
					)
				).locationOrderItems;

	const selectedAddresses =
		selectedLocations.length === 0
			? []
			: await queryClient.fetchQuery(cartQueryOptions.selectedAddresses(cartId));

	const validationResult = validateStep({
		stepName: 'review',
		selectedLocations: selectedLocations,
		selectedProducts: selectedProducts,
		selectedQuantities: selectedQuantities,
		selectedAddresses: selectedAddresses,
	});

	return validationResult.step;
}

type ValidateStepOptions = {
	stepName: StepName;
	selectedLocations?: LocationId[];
	selectedProducts?: ProductKey[];
	selectedQuantities?: LocationOrder[];
	selectedAddresses?: OrderShipToAddress[];
};

type ValidateStepResults = {
	isValid: boolean;
	step: StepName;
	count?: number;
};

export function validateStep({
	stepName,
	selectedLocations,
	selectedProducts,
	selectedQuantities,
	selectedAddresses,
}: ValidateStepOptions): ValidateStepResults {
	const locationCount = selectedLocations?.length ?? 0;
	const locationsValid = locationCount > 0;
	if (!locationsValid) {
		return {
			isValid: false,
			step: 'locations',
		};
	}

	if (stepName === 'locations') {
		return {
			isValid: true,
			step: 'locations',
		};
	}

	const productCount = selectedProducts?.length ?? 0;
	const productsValid = productCount > 0;
	if (!productsValid) {
		return {
			isValid: false,
			step: 'products',
		};
	}

	if (stepName === 'products') {
		return {
			isValid: true,
			step: 'products',
		};
	}

	const locationsWithNonZeroQuantity = selectedQuantities?.filter(locationOrderItem =>
		locationOrderItem.programOrderItems.some(programOrderItem =>
			programOrderItem.items.some(item => item.quantity ?? 0 > 0),
		),
	);

	const locationsWithNonZeroQuantityCount = locationsWithNonZeroQuantity?.length ?? 0;
	const quantitiesValid = locationsWithNonZeroQuantityCount > 0;
	if (!quantitiesValid) {
		console.log('invalid quantities:', { selectedQuantities, locationsWithNonZeroQuantity });
		return {
			isValid: false,
			step: 'quantities',
		};
	}

	if (stepName === 'quantities') {
		return {
			isValid: true,
			step: 'quantities',
		};
	}

	const selectedAddressCount = selectedAddresses?.length ?? 0;
	const addressesValid =
		selectedAddressCount > 0 &&
		locationsWithNonZeroQuantityCount > 0 &&
		selectedAddressCount >= locationsWithNonZeroQuantityCount;

	return {
		isValid: addressesValid,
		step: 'review',
		count: addressesValid ? undefined : locationsWithNonZeroQuantityCount,
	};
}

type ValidationResultTranslationOptions = {
	t: TFunction;
	validationResult: ValidateStepResults;
};

export function translateValidationResult({
	t,
	validationResult: { isValid, step, count },
}: ValidationResultTranslationOptions): string | null {
	if (isValid) {
		return null;
	}

	switch (step) {
		case 'locations':
			return t('shop:error.noLocationsSelected', { count });
		case 'products':
			return t('shop:error.noProductsSelected', { count });
		case 'quantities':
			return t('shop:error.noQuantitiesSelected', { count });
		case 'review':
			return t('shop:error.noShippingAddressSelected', { count });
	}
}
