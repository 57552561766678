import { Input, rem } from '@mantine/core';

export const InputTheme = Input.extend({
	vars: () => ({
		wrapper: {
			'--input-height-xs': rem(16),
			'--input-height-sm': rem(24),
			'--input-height-md': rem(28),
			'--input-height-lg': rem(32),
			'--input-height-xl': rem(36),
			'--input-radius': rem(6),
		},
	}),
});
