import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useStore } from '@tanstack/react-store';
import { Store } from '@tanstack/store';
import type { AppShellNavbarConfiguration } from '@mantine/core';

interface LeftSidebarState extends AppShellNavbarConfiguration {
	collapsed: {
		desktop: boolean;
		mobile: boolean;
	};
}

export const leftSidebarStore = new Store<LeftSidebarState>({
	width: 360,
	breakpoint: 'sm',
	collapsed: {
		desktop: false,
		mobile: true,
	},
});

export function toggleLeftSidebar() {
	leftSidebarStore.setState(prev => ({
		...prev,
		collapsed: {
			...prev.collapsed,
			desktop: !prev.collapsed.desktop,
			mobile: !prev.collapsed.mobile,
		},
	}));
}

export function useLeftSidebarProps() {
	return useStore(leftSidebarStore);
}

export function useLeftSidebarCollapsed() {
	const theme = useMantineTheme();
	const collapsedState = useStore(leftSidebarStore, x => x.collapsed);
	const isSmallerThanBreakpoint = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false, {
		getInitialValueInEffect: false,
	});

	return {
		isCollapsed:
			(isSmallerThanBreakpoint ? collapsedState.mobile : collapsedState.desktop) ?? false,
		toggle: toggleLeftSidebar,
		close: () => {
			leftSidebarStore.setState(prev => ({
				...prev,
				collapsed: {
					...prev.collapsed,
					desktop: isSmallerThanBreakpoint ? prev.collapsed.desktop : true,
					mobile: isSmallerThanBreakpoint ? true : prev.collapsed.mobile,
				},
			}));
		},
		isSmallerThanBreakpoint,
	};
}
