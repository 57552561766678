import { List, Paper, ScrollArea, Text } from '@mantine/core';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { useTranslation } from '@apple/lib/i18next';
import { TableBody } from '@apple/ui/data-table';

import type { ExcelError } from '../models/excel';

export type ErrorTableProps<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
> = {
	keyProperty: TKey;
	keyLabel: string;
	errors?: TError[];
	errorsProperty?: keyof TError;
};

export function ErrorTable<
	TItem extends Record<string, unknown>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey>,
>({ keyProperty, keyLabel, errors = [], errorsProperty }: ErrorTableProps<TItem, TKey, TError>) {
	const { t } = useTranslation('manage');
	const table = useReactTable({
		data: errors,
		rowCount: errors.length,
		enableFilters: false,
		enableColumnFilters: false,
		enableSorting: false,
		manualFiltering: false,
		manualSorting: false,
		manualPagination: false,
		getCoreRowModel: getCoreRowModel(),
		columns: [
			{
				accessorKey: keyProperty,
				header: keyLabel ?? t('excelUpload.results.labels.keyField'),
				enableGlobalFilter: false,
				enableColumnFilter: false,
				enableSorting: false,
			},
			{
				accessorKey: errorsProperty,
				header: t('excelUpload.results.labels.errors'),
				size: 500,
				enableColumnFilter: false,
				enableGlobalFilter: false,
				enableSorting: false,
				cell: ({ row }) => (
					<List
						listStyleType='none'
						key={`item-${row.id}`}
						data-testid={`item-${row.id}`}
					>
						{Object.entries(row.original.errors).map(([key, value]) => (
							<List.Item key={`field-${key}`} data-testid={`field-${key}`}>
								{key}
								<List withPadding>
									{value.map((error, i) => (
										<List.Item
											key={`error-${key}-${i}`}
											data-testid={`error-${key}-${i}`}
										>
											<Text>{error}</Text>
										</List.Item>
									))}
								</List>
							</List.Item>
						))}
					</List>
				),
			},
		],
	});

	return (
		<Paper withBorder radius='sm' w={'100%'}>
			<ScrollArea.Autosize mah={400}>
				<TableBody table={table} striped={false} />
			</ScrollArea.Autosize>
		</Paper>
	);
}
